import React from "react";
import Header from "../../molecules/header/Header";
import classes from "./RegisterComplete.module.scss";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import { Navigate, useNavigate } from "react-router-dom";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import teacherIcon from "../../static/images/teacher-ok.svg";

const RegisterComplete = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.body}>
        <img
          className={classes.teacherImage}
          src={teacherIcon}
          alt={teacherIcon}
        />
        <div className={classes.title}>本登録 完了</div>
        <div className={classes.caption}>ご登録ありがとうございます。</div>

        <div className={classes.button}>
          <SimpleButton
            label="診断結果の続きを見る"
            width="100%"
            color="orange"
            isRadius={true}
            onClick={() => navigate("/diagnosis-result")}
          ></SimpleButton>
        </div>
        <div className={classes.button}>
          <SimpleButton
            label="◯×クイズに挑戦してみる"
            width="100%"
            color="orange"
            isRadius={true}
            onClick={() => navigate("/quiz-list")}
          ></SimpleButton>
        </div>
        <div className={classes.breadCrumb}>
          <BreadCrumb
            data={[
              { displayName: "みらいいコンシェル" },
              { displayName: "本登録" },
              { displayName: "完了" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterComplete;
