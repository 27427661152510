import React, { useState, useEffect, useContext } from "react";
import classes from "./DiagnosisResult.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionHeader from "../../organism/questionHeader/QuestionHeader";
import {
  DiagnosisResults,
  fetchDiagnosis,
  fetchDiagnosisAtom,
  postDiagnosisAtom,
} from "../../api/UsersDiagnosisApi";
import { postUnregisteredUsers } from "../../api/UsersApi";
import { atom, useAtom } from "jotai";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { round } from "../../common/utility/number.util";
import {
  fetchUsersEducationFee,
  postUnregisteredUsersEducationFee,
  calculateUnregisteredUsersEducationFeeAtom,
  UsersEducationFee,
} from "../../api/UsersEducationApi";
import { postEducationFeeParamAtom } from "../diagnosis-contents/DiagnosisContents";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherOk from "../../static/images/teacher-ok.svg";
import teacherNormal from "../../static/images/teacher-normal.svg";
import lockIcon from "../../static/images/lock-icon-orange.svg";
import lpTitleImage from "../../static/images/LpTitleImage.png";
import lpImage1 from "../../static/images/LpImage1.png";
import lpImage2 from "../../static/images/LpImage2.png";
import lpImage3 from "../../static/images/LpImage3.png";
import lpImage4 from "../../static/images/LpImage4.png";
import lpImage5 from "../../static/images/LpImage5.png";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import { validators } from "../../common/utility/validator.util";
import { State } from "../../api/State";
import EducationFeeCard from "../../molecules/education-fee-card/EducationFeeCard";

//ロゴファイルの動的読み込み
const logos = Array.from({ length: 12 }).map((_, index) => {
  const typeNumber = index + 1;
  return require(`../../static/images/resultLogo${typeNumber}.png`);
});

const messageLogos = Array.from({ length: 9 }).map((_, index) => {
  const typeNumber = index + 1;
  return require(`../../static/images/messageLogo${typeNumber}.png`);
});

const getResultLogoPath = (diagnosisType: string | undefined) => {
  if (diagnosisType == null) {
    return logos[0];
  }

  const typeNumber = Number(diagnosisType.replace("type", ""));
  return logos[typeNumber - 1];
};

const getMessgaeLogoPath = (typeNumber: number) => {
  return messageLogos[typeNumber - 1];
};

//テキスト文章の改行コード変換
const getNewLineText = (text: string | undefined) => {
  return text?.split("\n").map((str, index, array) => {
    // 改行のみの行を検出
    if (str === "") {
      // 2つの改行を挿入して、それぞれにユニークなkeyを割り当てる
      return (
        <React.Fragment key={`empty-${index}`}>
          <br key={`br1-${index}`} />
        </React.Fragment>
      );
    }
    // 文字列が含まれる行では、spanで囲み、最後でなければ改行を追加
    return (
      <span key={`line-${index}`}>
        {str}
        {index !== array.length - 1 && <br />}
      </span>
    );
  });
};

const getColorClassForChild = (
  type: string,
  currentType: string | undefined
) => {
  if (currentType != null && type === currentType) {
    switch (type) {
      case "Rule":
        return "blue";
      case "Balance":
        return "green";
      case "Free":
        return "orange";
      default:
        return "gray";
    }
  }
  return "gray";
};

const getColorClassForMoney = (
  type: string,
  currentType: string | undefined
) => {
  if (currentType != null && type === currentType) {
    switch (type) {
      case "Aggressive":
        return "orange";
      case "Balance":
        return "green";
      case "Conservative":
        return "blue";
      default:
        return "gray";
    }
  }
  return "gray";
};

export const mailAtom = atom<string>("");

type Props = {
  isUnregistered: boolean;
};
const DiagnosisResult = (props: Props) => {
  const navigate = useNavigate();

  // 仮登録時の前画面から引き継いだAtom
  const [diagnosisAtom] = useAtom(postDiagnosisAtom);
  const [diagnosisResultAtom] = useAtom(fetchDiagnosisAtom);
  const [educationFeeAtom] = useAtom(
    calculateUnregisteredUsersEducationFeeAtom
  );
  // 仮登録の時にAPIリクエストのパラメータとして使う
  const [educationFeeParam] = useAtom(postEducationFeeParamAtom);

  // const [diagnosis, setDiagnosis] = useState<State<DiagnosisFetchRequestType>>();
  const [educationFee, setEducationFee] = useState<State<UsersEducationFee>>();
  const [diagnosisResult, setDiagnosisResult] =
    useState<State<DiagnosisResults>>();

  const fetchDiagnosisApi = fetchDiagnosis();
  const fetchUsersEducationFeeApi = fetchUsersEducationFee();

  // const fetchUnregisteredDiagnosisApi = fetchUnregisteredDiagnosis();

  useEffect(() => {
    if (props.isUnregistered) {
      // 会員登録前の場合、前画面から引き継いだatomを使う
      // 登録前の結果画面でリロードなどされた場合保持データ消えちゃうので、トップ遷移にする
      if (
        diagnosisAtom == null ||
        diagnosisResultAtom == null ||
        educationFeeAtom == null
      ) {
        navigate("/top");
      } else {
        setDiagnosisResult(diagnosisResultAtom);
        setEducationFee(educationFeeAtom);
      }
      // 会員登録済の場合普通にAPIで取る
    } else {
      fetchDiagnosisApi.fetch();
      fetchUsersEducationFeeApi.fetch();
    }
  }, []);

  useEffect(() => {
    if (props.isUnregistered) {
      return;
    }

    if (
      fetchDiagnosisApi.state?.data == null ||
      fetchUsersEducationFeeApi.state?.data == null
    ) {
      return;
    }

    setDiagnosisResult(fetchDiagnosisApi.state);
    setEducationFee(fetchUsersEducationFeeApi.state);
  }, [fetchDiagnosisApi, fetchUsersEducationFeeApi]);

  const postUnregisteredUserApi = postUnregisteredUsers();
  const postUnregisteredUsersEducationFeeApi =
    postUnregisteredUsersEducationFee();

  // const [isUnRegistered, setIsUnRegistered] = useState<boolean>(true);
  const [doneRegister, setDoneRegister] = useState<boolean>(false);
  const [mail, setMail] = useAtom(mailAtom);
  const mailValidator = (value: string) => {
    const required = validators.required(value);

    if (!required.status) {
      return required;
    }

    return validators.mail(value);
  };

  const register = () => {
    const diagnosisType = diagnosisAtom?.data?.[0].diagnosisType;
    if (educationFeeParam == null || diagnosisType == null) {
      return;
    }

    postUnregisteredUsersEducationFeeApi.post({
      childrenEducation: educationFeeParam,
      emailAddress: mail,
      isRegistered: true,
    });

    postUnregisteredUserApi.post({
      diagnosisType,
      emailAddress: mail,
    });
    setDoneRegister(true);
  };

  useEffect(() => {
    if (
      doneRegister &&
      postUnregisteredUsersEducationFeeApi.state?.status === "success" &&
      postUnregisteredUserApi.state?.status === "success"
    ) {
      navigate("/register-result");
    }
  }, [postUnregisteredUsersEducationFeeApi, postUnregisteredUserApi]);

  useEffect(() => {
    // 画面上部から始まるようにする処理。
    // 全ての画面で利用できるようにすべき？
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.headerContainer}>
          <QuestionHeader />
          <div className={classes.typeContainer}>
            <div className={classes.subTitle}>
              {diagnosisResult?.data?.[0].typeTitle}
            </div>
            <span className={classes.mainTitle}>
              {diagnosisResult?.data?.[0].typeName}
            </span>
            <span className={classes.subType}>タイプ</span>
          </div>
          <div className={classes.typeExplanation}>
            <div className={classes.typeFuture}>
              {diagnosisResult?.data?.[0].typeFeature}
            </div>
          </div>
          <div className={classes.logoContainer}>
            <img
              src={getResultLogoPath(diagnosisResult?.data?.[0].diagnosisType)}
              alt="Logo"
            />
          </div>
        </div>

        <div className={classes.barContainer}>
          <div className={classes.barTitle}>子育てのスタイル</div>
          <div className={classes.bar}>
            <div
              className={`${classes.barLeft} ${classes[getColorClassForChild("Rule", diagnosisResult?.data?.[0].childTendency)]}`}
            >
              ルール
            </div>
            <div
              className={`${classes.barCenter} ${classes[getColorClassForChild("Balance", diagnosisResult?.data?.[0].childTendency)]}`}
            >
              バランス
            </div>
            <div
              className={`${classes.barRight} ${classes[getColorClassForChild("Free", diagnosisResult?.data?.[0].childTendency)]}`}
            >
              フリー
            </div>
          </div>

          <div className={classes.barTitle}>資産形成のスタイル</div>
          <div className={classes.bar}>
            <div
              className={`${classes.barLeft} ${classes[getColorClassForMoney("Conservative", diagnosisResult?.data?.[0].moneyTendency)]}`}
            >
              守り
            </div>
            <div
              className={`${classes.barCenter} ${classes[getColorClassForMoney("Balance", diagnosisResult?.data?.[0].moneyTendency)]}`}
            >
              バランス
            </div>
            <div
              className={`${classes.barRight} ${classes[getColorClassForMoney("Aggressive", diagnosisResult?.data?.[0].moneyTendency)]}`}
            >
              攻め
            </div>
          </div>
        </div>
        <div className={classes.agendaContainer}>
          <TeacherComment
            image={teacherNormal}
            comment={[
              "子育てと資産形成に役立つヒントをまとめましたのでご覧ください",
            ]}
          />
          <div className={classes.agenda}>
            <div className={classes.agendaTitle}>目次</div>
            <div className={classes.linkBlock}>
              <a href="#education-tendency" className={classes.link}>
                1. 子育ての傾向
              </a>
            </div>
            <div className={classes.linkBlock}>
              <a href="#asset-tendency" className={classes.link}>
                2. 資産形成の傾向
              </a>
            </div>
            <div className={classes.linkBlock}>
              <a className={classes.link} href="#education-fee">
                3. 教育資金の目安
              </a>
            </div>
            <div className={classes.linkBlock}>
              <a className={classes.link} href="#recommend-action">
                4. おすすめのアクション{props.isUnregistered && "（会員限定）"}
              </a>
            </div>
          </div>
        </div>
        <div id="education-tendency" className={classes.messageBoxContainer}>
          <div className={classes.messageBox}>
            <h2 className={classes.messageBoxTitle}>子育ての傾向</h2>
            <div className={classes.logoContainer}>
              <img src={getMessgaeLogoPath(2)} alt="Logo" />
            </div>
            <h3 className={classes.messageBoxSubtitle}>
              {diagnosisResult?.data?.[0].childTendTitle}
            </h3>
            <p className={classes.messageBoxContent}>
              {getNewLineText(diagnosisResult?.data?.[0].childTendExpl)}
            </p>
          </div>
        </div>

        <div id="asset-tendency" className={classes.messageBoxContainer}>
          <div className={classes.messageBox}>
            <h2 className={classes.messageBoxTitle}>資産形成の傾向</h2>
            <div className={classes.logoContainer}>
              <img src={getMessgaeLogoPath(1)} alt="Logo" />
            </div>
            <h3 className={classes.messageBoxSubtitle}>
              {diagnosisResult?.data?.[0].moneyTendTitle}
            </h3>
            <p className={classes.messageBoxContent}>
              {getNewLineText(diagnosisResult?.data?.[0].moneyTendExpl)}
            </p>
          </div>
          <div id="education-fee" className={classes.blockContainer}>
            <EducationFeeCard
              logoImage={getMessgaeLogoPath(4)}
              totalTuitionFee={educationFee?.data?.[0].totalTuitionFee}
              totalTuitionFeeMonthlyBased={
                educationFee?.data?.[0].totalTuitionFeeMonthlyBased
              }
              totalTuitionFeeYearlyBased={
                educationFee?.data?.[0].totalTuitionFeeYearlyBased
              }
            />
          </div>
          {props.isUnregistered ? (
            <div id="recommend-action" className={classes.messageBox}>
              <h2 className={classes.messageBoxTitle}>おすすめアクション</h2>
              <div className={classes.logoContainer}>
                <img src={getMessgaeLogoPath(5)} alt="Logo" />
              </div>
              <div className={classes.lockContainer}>
                <img src={lockIcon} alt={lockIcon} />
                <div className={classes.lockText}>会員限定</div>
              </div>
            </div>
          ) : (
            <div id="recommend-action" className={classes.messageBox}>
              <h2 className={classes.messageBoxTitle}>おすすめアクション</h2>
              <div className={classes.logoContainer}>
                <img src={getMessgaeLogoPath(5)} alt="Logo" />
              </div>
              <div className={classes.messageSubBoxContent}>
                <p className={classes.messageSubBoxTitle}>子育てのヒント</p>
                <p className={classes.messageSubBoxTitleExplanation}>
                  {diagnosisResult?.data?.[0]?.childActionTitle}
                </p>
              </div>
              <p className={classes.messageBoxContent}>
                {getNewLineText(diagnosisResult?.data?.[0]?.childActionExpl)}
              </p>

              <div className={classes.messageSubBoxContent}>
                <p className={classes.messageSubBoxTitle}>資産形成のヒント</p>
                <p className={classes.messageSubBoxTitleExplanation}>
                  {diagnosisResult?.data?.[0]?.moneyActionTitle}
                </p>
              </div>
              <p className={classes.messageBoxContent}>
                {getNewLineText(diagnosisResult?.data?.[0]?.moneyActionExpl)}
              </p>
              <div className={classes.messageSubBoxContent}>
                <p className={classes.messageSubBoxTitle}>金融教育のヒント</p>
                <p className={classes.messageSubBoxTitleExplanation}>
                  {diagnosisResult?.data?.[0].moneyEducationTitle}
                </p>
              </div>
              <p className={classes.messageBoxContent}>
                {getNewLineText(diagnosisResult?.data?.[0].moneyEducationExpl)}
              </p>
            </div>
          )}
        </div>
        {props.isUnregistered ? (
          <>
            <div className={classes.blockContainer}>
              <TeacherComment
                image={teacherOk}
                comment={[
                  "診断結果はいかがでしたか？",
                  "子育てや資産形成についてもっと深く知りたい方にはみらいいコンシェルがオススメです♪",
                ]}
              />
            </div>
            <div className={classes.lpContainer}>
              <div className={classes.lpTitle}>
                  会員登録＆利用{" "}
                  <span className={classes.strong}>
                    完全無料
                    <div className={classes.text}>完全無料</div>
                    <div className={classes.line}></div>
                  </span>
              </div>
             <div className={classes.lpHeader}>
                <div className={classes.lpHeader1}>
                  子育てとおカネの学びアプリ
                </div>
                <div className={classes.lpHeader2}>
                  みらいいコンシェル
                  <span className={classes.lpSubheader}>の特徴</span>
                </div>
              </div>
              <div className={classes.lpImageContainer}>
                <img
                  className={classes.lpImage}
                  src={lpImage1}
                  alt={lpImage1}
                />
                <img
                  className={classes.lpImage}
                  src={lpImage2}
                  alt={lpImage2}
                />
                <img
                  className={classes.lpImage}
                  src={lpImage3}
                  alt={lpImage3}
                />
                <img
                  className={classes.lpImage}
                  src={lpImage4}
                  alt={lpImage4}
                />
                <img
                  className={classes.lpImageLarge}
                  src={lpImage5}
                  alt={lpImage5}
                />
              </div>
            </div>
            <div className={classes.registerContainer}>
              <div className={classes.registerTitle}>会員登録（無料）</div>
              <div className={classes.registerHeader}>
                ドメイン指定受信を設定されている方にはメールが正しく届かないことがあります。
              </div>
              <div className={classes.registerHeader}>
                「@miraii-concierge.jp」の受信許可をお願いします。
              </div>
              <div className={classes.registerForm}>
                <TextForm
                  label="メールアドレス"
                  type="text"
                  name={"mail"}
                  value={mail}
                  color="white"
                  onChange={(args: TextFormResult) => setMail(args.value)}
                  validator={mailValidator}
                />
              </div>
              <SimpleButton
                label="利用規約に同意して登録する"
                width="100%"
                color="orange"
                isRadius={true}
                onClick={register}
                disabled={mailValidator(mail).status === false}
              ></SimpleButton>
              <div className={classes.registerCaption}>
                <a target="_blank" href="serviceterm" className={classes.link}>
                  利用規約
                </a>
                <span>に同意の上、登録へお進みください。</span>
              </div>
            </div>
            <BreadCrumb
              data={[
                { displayName: "みらいいコンシェル" },
                {
                  displayName: "子育てとおカネの9タイプ診断結果",
                  url: "/diagnosis-result",
                },
              ]}
            />
          </>
        ) : (
          <>
            <div className={classes.diagnosisButton}>
              <SimpleButton
                label="もう一度診断してみる"
                width="80%"
                color="black"
                isRadius={true}
                onClick={() => navigate("/diagnosis-contents")}
              ></SimpleButton>
            </div>
            <div className={classes.myPageButton}>
              <SimpleButton
                label="マイページ"
                width="80%"
                color="orange"
                isRadius={true}
                onClick={() => navigate("/my-page")}
              ></SimpleButton>
            </div>
            <BreadCrumb
              data={[
                { displayName: "みらいいコンシェル", url: "/my-page" },
                {
                  displayName: "子育てとおカネの9タイプ診断結果",
                  url: "/diagnosis-result",
                },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DiagnosisResult;
