import React, { ReactNode } from "react";
import classes from "./FrameTemplate.module.scss";
import vectorIcon from "../../static/images/arrow-right.svg";

type FrameTemplateProps = {
  children: ReactNode;
  frameTitle: string[];
  frameContentPadding?: string;
  onClick?: () => void;
  noVector?: boolean;
};

const FrameTemplate = (props: FrameTemplateProps) => {
  const { children, noVector } = props;
  return (
    <div className={classes.container} onClick={props.onClick}>
      <div
        className={classes.frameContent}
        style={{ padding: props.frameContentPadding }}
      >
        {children}
      </div>
      <div className={classes.frameTitle}>
        {props.frameTitle?.map((t, index) => (
          <div key={`frame-title-${index}`}>{t}</div>
        ))}
      </div>
      {!noVector && (
        <div className={classes.vectorIcon}>
          <img src={vectorIcon} alt="vector" />
        </div>
      )}
    </div>
  );
};
export default FrameTemplate;
