import React, { useEffect, useState } from "react";
import classes from "./Header.module.scss";
import icon from "../../static/images/header_icon.png";
import { useAtom } from "jotai";
import { sideMenuAtom } from "../../state/side-menu/SideMenuAtom";
import { fetchUser } from "../../api/UsersApi";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../api/LogoutApi";
import HeaderIcon from "../../static/images/menu-icon.svg";

type HeaderProps = {
  hideMenu?: true;
  nonCalledUser?: boolean;
};

const Header = (props: HeaderProps) => {
  const [state, setState] = useAtom(sideMenuAtom);
  const [userName, setUserName] = useState<string | null>(null);
  const fetchUserApi = fetchUser();
  const logoutApi = logout();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!props.nonCalledUser) {
      fetchUserApi.fetch();
    }
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }
    const user = fetchUserApi.state?.data[0];

    //初期ログイン画面遷移せずにヘッダーが描画された場合
    if (user?.isFirstLogin) {
      navigate("/first-setting");
    }

    // userNameがnullの場合はどうするか
    const name = user?.userName ?? "ゲスト";

    if (name == null) {
      return;
    }
    setUserName(name);
  }, [fetchUserApi.state?.data]);

  useEffect(() => {
    if (logoutApi.state?.status === "success") {
      setUserName(null);
    }
  }, [logoutApi.state?.status]);

  // ヘッダーアイコン押下時のページ遷移
  // ログインページ以外は全てマイページに遷移
  const handleImageClick = () => {
    if (location.pathname !== "/login") {
      navigate("/my-page");
    }
  };

  const openMenu = () => {
    if (userName == null) {
      return;
    }
    setState({
      isShow: true,
      userName,
    });
  };

  return (
    <div className={classes.container}>
      <img
        className={classes.img}
        src={icon}
        alt={icon}
        onClick={handleImageClick}
      />
      {props.hideMenu == null && userName != null && (
        <img
          src={HeaderIcon}
          alt={HeaderIcon}
          className={classes.headerIcon}
          onClick={openMenu}
        />
      )}
    </div>
  );
};

export default Header;
