import React from "react";
import classes from "./EducationFeeCard.module.scss";
import { round } from "../../common/utility/number.util";

type Props = {
  color?: "blue";
  titleSize?: number;
  logoImage?: string;
  totalTuitionFee: number | undefined;
  totalTuitionFeeMonthlyBased: number | undefined;
  totalTuitionFeeYearlyBased: number | undefined;
};

const EducationFeeCard = (props: Props) => {
  const getOuterCardStyle = () => {
    if (props.color === "blue") {
      return { backgroundColor: "#E8F0EE" };
    }

    return {
      backgroundColor: "#f9f7f3",
    };
  };

  const getTitleStyle = () => {
    if (props.titleSize != null) {
      return { fontSize: `${props.titleSize}rem` };
    }

    return {};
  };
  return (
    <div className={classes.container}>
      <div className={classes.outerCard} style={getOuterCardStyle()}>
        <div className={classes.title} style={getTitleStyle()}>
          教育資金の目安
        </div>
        {props.logoImage != null && (
          <div className={classes.logoContainer}>
            <img src={props.logoImage} alt={props.logoImage} />
          </div>
        )}
        <div className={classes.card}>
          <div className={classes.cardTitle}>
            これから必要になる教育費の総額
          </div>
          <div className={classes.cardBody}>
            <div className={classes.block}>
              <span className={classes.value}>
                {round((props.totalTuitionFee ?? 0) / 10000, "ceil", 0)}
              </span>
              <span className={classes.unit}>万円</span>
            </div>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.cardTitle}>
            子ども全員が高校卒業するまでの積立額
          </div>
          <div className={classes.cardBody}>
            <div className={classes.block}>
              <span className={classes.label}>月額</span>
              <span className={classes.value}>
                {round(
                  (props.totalTuitionFeeMonthlyBased ?? 0) / 10000,
                  "ceil",
                  0
                )}
              </span>
              <span className={classes.unit}>万円</span>
            </div>
            <div className={classes.block}>
              <span className={classes.label}>年間</span>
              <span className={classes.value}>
                {round(
                  (props.totalTuitionFeeYearlyBased ?? 0) / 10000,
                  "ceil",
                  0
                )}
              </span>
              <span className={classes.unit}>万円</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationFeeCard;
