import { EducationPlan } from "../../organism/education-plan/EducationPlan";
import logo16 from "../../static/images/questionLogo16.png";

export const diagnosisEducationPlan: EducationPlan = {
  header: "最後の質問です",
  title: [
    "お子さまの将来を思い浮かべてください♪",
    "どんな学校へ進学してほしいですか？",
  ],
  logo: logo16,
  teacherComment: ["診断結果に表示される「教育資金の目安」の計算に使用します"],
};
