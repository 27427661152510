import React from "react";
import classes from "./TokenExpire.module.scss";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import { Navigate, useNavigate } from "react-router-dom";
import teacherImage from "../../static/images/teacher-bow.svg";
import headerImage from "../../static/images/DiagnosisQuestionHeader.png";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";

const TokenExpire = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <img
        className={classes.headerImage}
        src={headerImage}
        alt={headerImage}
      />
      <div className={classes.body}>
        <div className={classes.teacherImageContainer}>
          <img
            className={classes.teacherImage}
            src={teacherImage}
            alt={teacherImage}
          />
        </div>
        <div className={classes.title}>有効時間が経過しました</div>
        <div className={classes.caption}>
          有効時間を過ぎています。
          お手数おかけいたしますが、以下のボタンからもう一度、
          診断およびメールアドレスの登録をお願いします。
        </div>
        <div className={classes.button}>
          <SimpleButton
            label="診断TOPへ"
            width="100%"
            color="orange"
            isRadius={true}
            onClick={() => navigate("/top")}
          ></SimpleButton>
        </div>
      </div>
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル" },
          { displayName: "子育てとおカネの9タイプ診断結果" },
          { displayName: "有効時間経過" },
        ]}
      />
    </div>
  );
};

export default TokenExpire;
