import { atom } from "jotai";
import { State } from "./State";
import { fetchBase, postBase } from "./BaseApi";

export type DiagnosisPostRequestType = { [key: string]: number };
export type DiagnosisPostRequest = {
  answers: DiagnosisPostRequestType;
};

export type DiagnosisFetchRequestType = {
  diagnosisType: string;
};

export type DiagnosisResults = {
  childActionExpl: string;
  childActionTitle: string;
  childTendExpl: string;
  childTendTitle: string;
  childTendency: string;
  diagnosisType: string;
  impactExpl: string;
  impactTitle: string;
  moneyActionExpl: string;
  moneyActionTitle: string;
  moneyTendExpl: string;
  moneyTendTitle: string;
  moneyTendency: string;
  moneyEducationTitle:string,
  moneyEducationExpl:string,
  typeFeature: string;
  typeName: string;
  typeTitle: string;
};

const uri = "api/users/diagnosis";
const unregisteredUri = "api/unregistered-users/diagnosis";

export const postDiagnosisAtom = atom<State<DiagnosisFetchRequestType> | null>(
  null
);

export const postDiagnosis = () => {
  return postBase<DiagnosisFetchRequestType, DiagnosisPostRequest>(
    uri,
    postDiagnosisAtom
  );
};

export const postUnregisteredDiagnosis = () => {
  return postBase<DiagnosisFetchRequestType, DiagnosisPostRequest>(
    unregisteredUri,
    postDiagnosisAtom
  );
};

export const fetchDiagnosisAtom = atom<State<DiagnosisResults> | null>(null);
export const fetchDiagnosis = () => {
  return fetchBase<DiagnosisResults, DiagnosisFetchRequestType>(
    uri,
    fetchDiagnosisAtom
  );
};

export const fetchUnregisteredDiagnosis = () => {
  return fetchBase<DiagnosisResults, DiagnosisFetchRequestType>(
    unregisteredUri,
    fetchDiagnosisAtom
  );
};
