import React, { useEffect, useState } from "react";
import classes from "./EditOccupationPlan.module.scss";
import { useNavigate } from "react-router-dom";
import {
  ChildEducation,
  fetchUsersEducationPath,
  postUsersEducationFee,
} from "../../api/UsersEducationApi";
import dreamLogo from "../../static/images/dream.png";
import Header from "../../molecules/header/Header";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import OccupationPlan from "../../organism/occupation-plan/OccupationPlan";

const EditOccupationPlan = () => {
  const navigate = useNavigate();
  const postEducationPlan = postUsersEducationFee();
  const fetchUsersEducationPathApi = fetchUsersEducationPath();
  const [submitState, setSubmitState] = useState<boolean>(false);

  useEffect(() => {
    fetchUsersEducationPathApi.fetch();
  }, []);
  const submit = (children: ChildEducation[]) => {
    postEducationPlan.post({ childrenEducation: children });
    setSubmitState(true);
  };

  useEffect(() => {
    if (submitState && postEducationPlan.state?.status === "success") {
      navigate("/simulate-education");
    }
  }, [submitState, postEducationPlan]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.body}>
        <OccupationPlan
          content={{
            header: "教育資金シミュレーション",
            title: ["将来の夢・職業"],
            logo: dreamLogo,
            teacherComment: [
              "お子さまの将来の夢を思い浮かべてください♪その夢に最も近い職業を選択してください！",
            ],
            children:
              fetchUsersEducationPathApi.state?.data?.[0].userEducationPath,
            submitButtonLabel: "教育資金を計算する",
          }}
          prevCallback={() => navigate("/simulate-education")}
          submitCallback={submit}
        />
      </div>
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル", url: "/my-page" },
          {
            displayName: "教育資金シミュレーション",
            url: "/simulate-education",
          },
          { displayName: "職業選択" },
        ]}
      />
    </div>
  );
};

export default EditOccupationPlan;
