import React from "react";
import classes from "./QuestionHeader.module.scss";
import headerImage from "../../static/images/DiagnosisQuestionHeader.png";

const QuestionHeader = () => {
  return (
    <div className={classes.questionHeaderContainer}>
      <img src={headerImage} alt={headerImage} className={classes.image} />
    </div>
  );
};

export default QuestionHeader;
