import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase, putBase } from "./BaseApi";
import { RankCategory } from "./UsersActionApi";

type UserConfirm = {
  userId: string;
};

type UserConfirmParam = {
  token: string;
};

const uri = "api/unregistered-users/verify";

export const fetchUserConfirmAtom = atom<State<UserConfirm> | null>(null);
export const fetchUserConfirm = () => {
  return fetchBase<UserConfirm, UserConfirmParam>(uri, fetchUserConfirmAtom);
};
