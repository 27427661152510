import React, { useEffect } from "react";
import classes from "./QuizBadgeResult.module.scss";
import badgeGetImage from "../../static/images/badge-get.png";
import badgeNotGetImage from "../../static/images/badge-unaquired.png";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import ExportIcon from "../../static/images/export-icon.svg";
import SuppurtIcon from "../../static/images/support-image.svg";
import SuppurtMessage from "../../static/images/support-message.svg";
import { useLocation, useNavigate } from "react-router-dom";
import teacherImage from "../../static/images/teacher-bow.svg";

interface QuizBadgeResultProps {
  data: QuizBadgeResultData;
  close: () => void;
}

export type QuizBadgeResultData = {
  quizId: string;
  badgeExplanation: string;
  badgeImageData: string;
  badgeTitle: string;
  isAcquiredBadgeType: boolean;
  supportLink:string;
};

// バッチの種類や状況によって表示内容が異なる&アクションが完全に消えるかわからないため、一旦別でコンポーネント化する
const QuizBadgeResult = (props: QuizBadgeResultProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const close = (path: string) => {
    props.close();
    navigate(path);
  };
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        {props.data.isAcquiredBadgeType ? (
          <img
            className={classes.ribbonImage}
            src={badgeGetImage}
            alt={badgeGetImage}
          />
        ) : (
          <img
            className={classes.squareImage}
            src={badgeNotGetImage}
            alt={badgeNotGetImage}
          />
        )}
        {props.data.isAcquiredBadgeType ? (
          <>
            <div className={classes.title}>{props.data.badgeTitle}</div>
            <img
              className={classes.image}
              src={props.data.badgeImageData}
              alt={props.data.badgeImageData}
            />
          </>
        ) : (
          <div className={classes.nonBadgeArea}>
            <img src={teacherImage} alt={teacherImage} />
            <div className={classes.nonBadgeText}>おつかれさまでした！</div>
          </div>
        )}
        <div className={classes.text}>{props.data.badgeExplanation}</div>
        <div className={classes.buttonWrapper}>
          <SimpleButton
            label="ほかのクイズに挑戦する"
            onClick={() => close("/quiz-list")}
            width="calc(100% - 32rem)"
          />
        </div>
        <div className={classes.buttonWrapper}>
          <SimpleButton
            label="もういちど挑戦してみる"
            onClick={() => close(location.pathname + location.search)}
            width="calc(100% - 32rem)"
            color="black"
          />
        </div>
        <div className={classes.support}>
          <img
            src={SuppurtMessage}
            alt={SuppurtMessage}
            className={classes.supportMessage}
          />
          <img
            src={SuppurtIcon}
            alt={SuppurtIcon}
            className={classes.supportIcon}
          />
          <SimpleButton
            label="サポートを希望する"
            onClick={() => {
              const link = props.data.supportLink;
              window.open(link, "_blank"); 
            }}
            width="calc(100% - 32rem)"
            color="green"
            rightIcon={ExportIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizBadgeResult;
