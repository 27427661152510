import { atom } from "jotai";
import { State } from "./State";
import { fetchBase } from "./BaseApi";

export type Occupation = {
  occupation: string;
  occupationShortName: string;
  occupationLongName: string;
  salary: number;
};

export type OccupationResponse = {
  occupationList: Occupation[];
};

const uri = "api/occupation";

export const fetchOccupationAtom = atom<State<OccupationResponse> | null>(null);

export const fetchOccupation = () => {
  return fetchBase<OccupationResponse>(uri, fetchOccupationAtom);
};
