import { toNumber } from "./number.util";

type ValidatorType =
  | "required"
  | "number"
  | "positive_number"
  | "year"
  | "month"
  | "day"
  | "date"
  | "string"
  | "drop_list"
  | "mail"
  | "password";

export const validators: {
  [key in ValidatorType]: (value: string) => {
    status: boolean;
    message?: string;
  };
} = {
  number: (value: string) => {
    const v = toNumber(value);

    if (v != null && isNaN(v)) {
      return { status: false, message: "数値を入力してください" };
    }

    return { status: true };
  },
  positive_number: (value: string) => {
    const s = validators.number(value);

    if (!s.status) {
      return s;
    }

    const v = toNumber(value);

    if (v != null && v < 0) {
      return { status: false, message: "マイナスは入力できません" };
    }

    return { status: true };
  },
  year: (value: string) => {
    const message = "西暦を入力してください";

    if (value == null || value === "") {
      return { status: true };
    }

    const regex = /^\d{4}$/;
    if (!regex.test(value)) {
      return { status: false, message };
    }

    return { status: true };
  },
  month: (value: string) => {
    const message = "1~12の月を入力してください";
    if (value == null || value === "") {
      return { status: true };
    }

    const regex = /^\d+$/;

    if (!regex.test(value)) {
      return { status: false, message };
    }

    const v = toNumber(value);

    if (v != null && 1 <= v && v <= 12) {
      return { status: true };
    }

    return { status: false, message };
  },
  day: (value: string) => {
    const message = "日付を入力してください";
    if (value == null || value === "") {
      return { status: true };
    }

    const regex = /^\d+$/;

    if (!regex.test(value)) {
      return { status: false, message };
    }

    const v = toNumber(value);

    if (v != null && 1 <= v && v <= 31) {
      return { status: true };
    }

    return { status: false, message };
  },
  date: (value: string) => {
    const message = "yyyy-mm-dd形式で入力してください";
    if (value == null || value === "") {
      return { status: true };
    }

    // 1800-01-01から、2999-12-31まで有効
    const regex = /^(1[89]|2[0-9])\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

    if (!regex.test(value)) {
      return { status: false, message };
    }

    return { status: true };
  },
  string: (value: string) => {
    return { status: true };
  },
  required: (value: string) => {
    if (value == null || value === "") {
      return { status: false, message: "入力してください" };
    }

    return { status: true };
  },
  drop_list: (value: string) => {
    // まあチェックしなくて良いか
    return { status: true };
  },
  mail: (value: string) => {
    if (value == null && value === "") {
      return { status: true };
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return {
      status: emailRegex.test(value),
      message: "メールアドレスの形式で入力してください",
    };
  },
  password: (value: string) => {
    if (value == null && value === "") {
      return { status: true };
    }
    const regex = /^[a-zA-Z0-9!@#$%^&*_+-=:|,.]{8,}$/;

    if (regex.test(value)) {
      return { status: true };
    }

    return {status: false, message: "英数字・記号(!@#$%^&*_+-=:|,.)のみで8文字以上入力してください"}

  }
};
