import React, { CSSProperties, useEffect, useState } from "react";
import classes from "./ActionCard.module.scss";
import arrowIcon from "../../static/images/arrow-right.svg";
import { RankCategory } from "../../api/UsersActionApi";
import rockIcon from "../../static/images/rockIcon.png";
import { toYYYYMMDDWithSlash } from "../../common/utility/date.util";

export type ActionCardType = {
  label: string;
  title: string;
  completedDate?: string;
  badge: string;
  disabled: boolean;
  rank?: RankCategory;
  onClick?: () => void;
};

type Props = {
  data: ActionCardType;
};

const ActionCard = (props: Props) => {
  const changeBackground = () => {
    if (props.data.disabled) {
      return {
        backgroundColor: "rgba(224, 224, 224, 1)",
        color: "rgba(116, 116, 116, 1)",
      };
    }
    return {};
  };

  return (
    <div className={classes.container} style={changeBackground()}>
      <div className={classes.header}>
        <div className={classes.label}>
          <div className={classes.triangle}></div>
          {props.data.label}
        </div>
        {props.data.completedDate != null && (
          <div
            className={classes.date}
          >{`${toYYYYMMDDWithSlash(props.data.completedDate)}完了`}</div>
        )}
        {props.data.disabled && props.data.rank != null && (
          <div className={classes.tagContainer}>
            <img src={rockIcon} alt="rockIcon" />
            <div className={classes.tag}>
              {props.data.rank === "gold" ? "ゴールド限定" : "シルバー限定"}
            </div>
          </div>
        )}
      </div>
      <div
        className={classes.body}
        onClick={() => !props.data.disabled && props.data.onClick?.()}
      >
        <img className={classes.badge} src={props.data.badge} />
        <div className={classes.title}>{props.data.title}</div>
        {!props.data.disabled && (
          <img className={classes.arrow} src={arrowIcon} />
        )}
      </div>
    </div>
  );
};

export default ActionCard;
