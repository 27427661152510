import React, { useEffect, useState } from "react";
import DiagnosisSlider from "../../molecules/diagnosis-slider/DiagnosisSlider";
import DiagnosisQuestion from "../../organism/diagnosis-question/DiagnosisQuestion";
import { diagnosisQuestionList } from "./question.const";
import headerImage from "../../static/images/DiagnosisQuestionHeader.png";
import classes from "./DiagnosisContents.module.scss";
import { atom, useAtom } from "jotai";
import {
  DiagnosisPostRequestType,
  fetchDiagnosis,
  fetchUnregisteredDiagnosis,
  postDiagnosis,
  postUnregisteredDiagnosis,
} from "../../api/UsersDiagnosisApi";
import { diagnosisEducationPlan } from "./education-plan.const";
import EducationPlan from "../../organism/education-plan/EducationPlan";
import {
  ChildEducation,
  calculateUnregisteredUsersEducationFee,
} from "../../api/UsersEducationApi";
import DiagnosisLoading from "../../organism/diagnosis-loading/DiagnosisLoading";
import { setTime } from "react-datepicker/dist/date_utils";
import { useNavigate } from "react-router-dom";

// こいつを結果画面に引き継ぐ
export const postEducationFeeParamAtom = atom<ChildEducation[] | null>(null);

type Props = {
  isUnregistered: boolean;
};
const DiagnosisContents = (props: Props) => {
  const navigate = useNavigate();
  const calculateUnregsisteredUsersEducationFeeApi =
    calculateUnregisteredUsersEducationFee();
  const postUnregsisteredDiagnosisApi = postUnregisteredDiagnosis();
  const fetchUnregisteredDiasnosisApi = fetchUnregisteredDiagnosis();
  const [diagnosisParam, setDiagnosisParam] =
    useState<DiagnosisPostRequestType>({});
  const [currentQuiz, setCurrentQuiz] = useState(1);

  const [display, setDisplay] = useState<"question" | "education">("question");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [, setPostEducationFeeParam] = useAtom(postEducationFeeParamAtom);

  const postDiagnosisApi = postDiagnosis();

  const answerCallback = (questionNo: number, answerNo: number) => {
    setDiagnosisParam({
      ...diagnosisParam,
      [questionNo.toString()]: answerNo,
    });
    if (questionNo === diagnosisQuestionList.length) {
      // 会員登録前なら子ども情報も
      if (props.isUnregistered) {
        setDisplay("education");
        setCurrentQuiz(questionNo + 1);
      } else {
        const param = { ...diagnosisParam, [questionNo.toString()]: answerNo };
        submitCallbackRegistered(param);
      }
    } else {
      setCurrentQuiz(questionNo + 1);
    }
  };

  const prevCallback = (questionNo: number) => {
    if (questionNo <= 1) {
      return;
    }

    setCurrentQuiz(questionNo - 1);
  };

  const submitCallbackUnregistered = (children: ChildEducation[]) => {
    setIsLoading(true);
    if (!props.isUnregistered) {
      return;
    }
    calculateUnregsisteredUsersEducationFeeApi.post({
      childrenEducation: children,
      isRegistered: false,
    });
    postUnregsisteredDiagnosisApi.post({
      answers: diagnosisParam,
    });
    setPostEducationFeeParam(children);
  };

  const submitCallbackRegistered = (param: DiagnosisPostRequestType) => {
    if (props.isUnregistered) {
      return;
    }

    setIsLoading(true);
    postDiagnosisApi.post({ answers: param });
  };

  // 仮登録中の場合はここで診断結果を取得してatomとして結果画面に渡す
  useEffect(() => {
    if (
      !props.isUnregistered ||
      postUnregsisteredDiagnosisApi.state?.data == null ||
      calculateUnregsisteredUsersEducationFeeApi.state?.data == null
    ) {
      return;
    }

    fetchUnregisteredDiasnosisApi.fetch({
      diagnosisType: postUnregsisteredDiagnosisApi.state.data[0].diagnosisType,
    });
  }, [
    postUnregsisteredDiagnosisApi,
    calculateUnregsisteredUsersEducationFeeApi,
  ]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    // 仮登録前と後で、最後のアクションが異なるので監視するものが変わる・・・
    if (props.isUnregistered) {
      if (fetchUnregisteredDiasnosisApi.state?.data == null) {
        return;
      }
    } else {
      if (postDiagnosisApi.state?.data == null) {
        return;
      }
    }

    setTimeout(() => {
      setIsLoading(false);
      if (props.isUnregistered) {
        navigate("/unregistered-diagnosis-result");
      } else {
        navigate("/diagnosis-result");
      }
    }, 5000);
  }, [fetchUnregisteredDiasnosisApi, postDiagnosis]);

  return (
    <>
      {isLoading ? (
        <DiagnosisLoading />
      ) : (
        <>
          <img src={headerImage} />
          <div className={classes.content}>
            <DiagnosisSlider
              currentNo={currentQuiz}
              totalNo={
                props.isUnregistered
                  ? diagnosisQuestionList.length + 1
                  : diagnosisQuestionList.length
              }
            />
            {display === "education" ? (
              <EducationPlan
                content={diagnosisEducationPlan}
                prevCallback={() => {
                  setDisplay("question");
                  prevCallback(currentQuiz);
                }}
                submitCallback={submitCallbackUnregistered}
              />
            ) : (
              <DiagnosisQuestion
                answerCallback={answerCallback}
                prevCallback={prevCallback}
                content={diagnosisQuestionList[currentQuiz - 1]}
                hidePrev={currentQuiz === 1}
                selectedNo={diagnosisParam?.[currentQuiz]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DiagnosisContents;
