import React from "react";
import classes from "./FinancialPlanBanner.module.scss";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import chara from "../../static/images/chara1.svg";
import FinaicialPlan1 from "../../static/images/financial-plan1.svg";
import FinaicialPlan2 from "../../static/images/financial-plan2.svg";
import FinaicialPlan3 from "../../static/images/financial-plan3.svg";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import ExportIcon from "../../static/images/export-black-icon.svg";

const FinancialPlanBanner = () => {
  const buttonClick = () => {
    window.open(
      "https://form.run/@miraii-concierge-soudankai",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div className={classes.container}>
      <div className={classes.bannerTop}>
        <div className={classes.teacherComment}>
          <TeacherComment
            image={chara}
            comment={[
              "みらいいコンシェルが厳選したファイナンシャルプランナーをご紹介します",
            ]}
          ></TeacherComment>
        </div>
      </div>
      <div className={classes.image1}>
        <img
          src={FinaicialPlan1}
          alt={FinaicialPlan1}
          className={classes.img}
        />
      </div>
      <div className={classes.image2}>
        <img
          src={FinaicialPlan2}
          alt={FinaicialPlan2}
          className={classes.img}
        />
      </div>
      <div className={classes.image3}>
        <img
          src={FinaicialPlan3}
          alt={FinaicialPlan3}
          className={classes.img}
        />
      </div>
      <div className={classes.buttonArea}>
        <SimpleButton
          label={"相談を希望する"}
          width="343rem"
          rightIcon={ExportIcon}
          onClick={buttonClick}
        ></SimpleButton>
      </div>
    </div>
  );
};

export default FinancialPlanBanner;
