import React, { createContext, useContext, useState, useEffect } from "react";
import classes from "./DiagnosisLoading.module.scss";
import charLoading from "../../static/images/Loading.png";
import headerImage from "../../static/images/DiagnosisQuestionHeader.png";

const Loading = () => {
  const [dot, setDot] = useState("");

  useEffect(() => {
    // ドットのアニメーションを開始
    const intervalId = setInterval(() => {
      setDot((prevDot) => (prevDot.length < 3 ? prevDot + "・" : ""));
    }, 1000); // 3秒間で全てのコマを表示

    // コンポーネントのクリーンアップ関数
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <>
      <div className={classes.loadingContainer}>
        <div className={classes.questionHeaderContainer}>
          <img src={headerImage} alt={headerImage} className={classes.image} />
        </div>
        <div className={classes.logoImageContainer}>
          <img src={charLoading} alt={charLoading} />
        </div>
        <p className={classes.message}>診断中{dot}</p>
      </div>
    </>
  );
};

export default Loading;
