import { atom } from "jotai";
import { State } from "./State";
import { postBase } from "./BaseApi";

type UserRegisterParam = {
  userId: string;
  userName: string;
  password: string;
};

const uri = "api/unregistered-users/register";

export const postUserRegisterAtom = atom<State<null> | null>(null);
export const postUserRegister = () => {
  return postBase<null, UserRegisterParam>(uri, postUserRegisterAtom);
};
