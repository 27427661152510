import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase, putBase } from "./BaseApi";
import { RankCategory } from "./UsersActionApi";

export type User = {
  createdAt: string;
  currentRank: RankCategory;
  diagnosisType: string;
  familyId: number;
  hashedPassword: string;
  isChildren: boolean;
  isPartner: boolean;
  lastAccessedAt: string;
  numberOfChildren: number;
  numberOfCompletedAction: number;
  numberOfCompletedYellowbadge: number;
  numberOfCompletedGreenbadge: number;
  numberOfCompletedRainbowbadge: number;
  updatedAt: string;
  userId: string;
  userName: string;
  familyProfile: FamilyProfile[];
  userTopic: UserTopic[];
  isFirstLogin: boolean;
  investmentReturn: number;
  fpId: number;
};

export type FamilyProfile = {
  familyName: string | null;
  familyRelationship: string | null;
  childrenRelationship: string | null;
  gender: string | null;
  birthdate: string | null;
  currentGrade: string | null;
  kindergarden: string | null;
  primarySchool: string | null;
  juniorSchool: string | null;
  highSchool: string | null;
  university: string | null;
  memberNumber: number | null;
};

export type UserTopic = {
  topicId: number;
  isInterest: string;
};

export type UserParam = {
  isPartner: boolean;
  userTopic: UserTopic[];
  familyProfile: Partial<FamilyProfile[]>;
};

export type PutUserParam = {
  familyProfile: Partial<FamilyProfile[]>;
};

export type PostUnregisteredUserParam = {
  emailAddress: string;
  diagnosisType: string;
};

const uri = "api/users";
const unregisteredUri = "api/unregistered-users/create";

export const fetchUserAtom = atom<State<User> | null>(null);

export const fetchUser = () => {
  return fetchBase<User>(uri, fetchUserAtom);
};

export const postUserAtom = atom<State<User> | null>(null);
export const postUsers = () => {
  return postBase<User, UserParam>(uri, postUserAtom);
};

export const putUserAtom = atom<State<User> | null>(null);
export const putUsers = () => {
  return putBase<User, PutUserParam>(uri, putUserAtom);
};

export const postUnregisteredUserAtom = atom<State<null> | null>(null);
export const postUnregisteredUsers = () => {
  return postBase<null, PostUnregisteredUserParam>(
    unregisteredUri,
    postUnregisteredUserAtom
  );
};
