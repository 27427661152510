import React, { useEffect, useState } from "react";
import classes from "./UserInfo.module.scss";
import beginer from "./../../static/images/BadgeBeginer.svg";
import bronze from "./../../static/images/BadgeBronze.svg";
import silver from "./../../static/images/BadgeSilver.svg";
import gold from "./../../static/images/BadgeGold.svg";
import platinum from "../../static/images/BadgePlatinum.svg";

type UserInfoProps = {
  userName?: string;
  userRank?: string;
};

const UserInfo = (props: UserInfoProps) => {
  const [badge, setBadge] = useState<string | null>(null);
  const badgeMapper = {
    beginer: "ビギナーランク",
    bronze: "ブロンズランク",
    silver: "シルバーランク",
    gold: "ゴールドランク",
    platinum: "プラチナランク",
  };

  useEffect(() => {
    if (props.userRank == null) {
      return;
    }
    switch (props.userRank) {
      case "beginer":
        setBadge(beginer);
        break;
      case "bronze":
        setBadge(bronze);
        break;
      case "silver":
        setBadge(silver);
        break;
      case "gold":
        setBadge(gold);
        break;
      case "platinum":
        setBadge(platinum);
        break;
    }
  }, []);
  return (
    <>
      <div className={classes.userInfo}>
        {badge != null && (
          <div className={classes.badgeWrapper}>
            <img src={badge} alt={"badge"} className={classes.badge} />
            <div className={classes.badgeName}>
              {props.userName?.slice(0, 3)}
            </div>
          </div>
        )}
        <div className={classes.userItem}>
          <div className={classes.userName}>{props.userName}</div>
          <div className={classes.userRank}>
            {props.userRank &&
              badgeMapper[props.userRank as keyof typeof badgeMapper]}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
