import React from "react";
import classes from "./QuizDetail.module.scss";
import Header from "../../molecules/header/Header";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-normal.svg";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import QuizHeader from "../../molecules/quiz-header/QuizHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Quiz } from "../../api/UsersQuiz";
import badgeGrayEdit from "../../static/images/BadgeGrayEdit.svg";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";

const QuizDetail = () => {
  const location = useLocation();
  const quizData: Quiz = location.state;
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.container}>
        <Header />
        <QuizHeader title="◯×クイズ" />
        <div className={classes.quizContainer}>
          <div className={classes.actionSubtitle}>
            {quizData.quizSummaryName}
          </div>
          <div className={classes.actionTitle}>{quizData.quizName}</div>
          <div className={classes.badgeContainer}>
            <img
              className={classes.acquiredBadgeType}
              src={quizData.badgeImageData ?? badgeGrayEdit}
              alt={quizData.badgeImageData ?? badgeGrayEdit}
            />
          </div>
          <TeacherComment
            image={teacherImage}
            comment={[
              "問題は全部で10問！すべて回答するとバッジがもらえます",
              "再挑戦は何度でも可能です♪",
            ]}
          />
          <div className={classes.button}>
            <SimpleButton
              label="◯×クイズ スタート"
              width="343rem"
              onClick={() => navigate(`/quiz-answer?quizId=${quizData.quizId}`)}
            ></SimpleButton>
          </div>
        </div>
      </div>
      <div className={classes.breadCrumb}>
        <BreadCrumb
          data={[
            { displayName: "みらいいコンシェル", url: "/my-page" },
            { displayName: "◯×クイズ一覧", url: "/quiz-list" },
            { displayName: `${quizData.quizName}` },
          ]}
        />
      </div>
    </>
  );
};

export default QuizDetail;
