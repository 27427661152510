import React, { useEffect, useState } from "react";
import classes from "./OccupationPlan.module.scss";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-normal.svg";
import { grade } from "../../pages/first-setting/FirstSetting-const";
import SelectBox, { SelectBoxOption } from "../../atoms/select-box/SelectBox";
import removeIcon from "../../static/images/remove-icon.svg";
import { ChildEducation } from "../../api/UsersEducationApi";
import { fetchOccupation } from "../../api/OccupationApi";

export type EducationPlan = {
  header: string;
  title: string[];
  logo: string;
  teacherComment: string[];
  children?: ChildEducation[];
  submitButtonLabel?: string;
};

type Props = {
  content: EducationPlan;
  prevCallback: () => void;
  submitCallback: (children: ChildEducation[]) => void;
};

const OccupationPlan = (props: Props) => {
  const fetchOccupationApi = fetchOccupation();
  const [occupationList, setOccupationList] = useState<
    {
      name: string;
      value: string;
    }[]
  >();
  useEffect(() => {
    fetchOccupationApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchOccupationApi.state?.data == null) {
      return;
    }

    setOccupationList(
      fetchOccupationApi.state.data?.[0].occupationList.map((o) => {
        return {
          name: o.occupationShortName,
          value: o.occupation,
        };
      })
    );
  }, [fetchOccupationApi]);
  const createInitParam = () => {
    return [
      {
        currentGrade: "preschool",
        kindergarden: "",
        primarySchool: "",
        juniorSchool: "",
        highSchool: "",
        university: "",
        desiredOccupation: "civil_servant",
      },
    ] as ChildEducation[];
  };
  const [childrenEducation, setChildrenEducation] =
    useState<ChildEducation[]>(createInitParam());

  useEffect(() => {
    if (props.content.children == null) {
      return;
    }

    const children = props.content.children.map((child) => {
      child.kindergarden = "";
      child.primarySchool = "";
      child.juniorSchool = "";
      child.highSchool = "";
      child.university = "";
      child.desiredOccupation =
        child.desiredOccupation == null || child.desiredOccupation === ""
          ? "civil_servant"
          : child.desiredOccupation;
      return child;
    });

    setChildrenEducation(children);
  }, [props]);

  const addChild = () => {
    const init = createInitParam();
    setChildrenEducation([...childrenEducation, ...init]);
  };

  const removeChild = (index: number) => {
    setChildrenEducation(childrenEducation.filter((c, i) => i !== index));
  };
  const changeGrade = (e: SelectBoxOption, parentIndex: number) => {
    childrenEducation[parentIndex].currentGrade = e.value;
    setChildrenEducation(childrenEducation.map((c) => c));
  };

  const changeOccupation = (e: SelectBoxOption, parentIndex: number) => {
    childrenEducation[parentIndex].desiredOccupation = e.value;
    setChildrenEducation(childrenEducation.map((c) => c));
  };

  const submit = () => {
    props.submitCallback(childrenEducation);
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>{props.content.header}</div>
      <div className={classes.title}>
        {props.content.title.map((t) => {
          return <div key={t}>{t}</div>;
        })}
      </div>
      <img className={classes.logo} src={props.content.logo} />
      <div className={classes.teacherComment}>
        <TeacherComment
          image={teacherImage}
          comment={props.content.teacherComment}
        />
      </div>
      <div className={classes.formBlock}>
        {childrenEducation.map((child, parentIndex) => {
          return (
            <div className={classes.formContent} key={parentIndex}>
              <div className={classes.formHeader}>第{parentIndex + 1}子</div>
              {parentIndex > 0 && (
                <div
                  className={classes.removeChild}
                  onClick={() => removeChild(parentIndex)}
                >
                  <img src={removeIcon} />
                  <span className={classes.removeText}>
                    第{parentIndex + 1}子の情報を削除
                  </span>
                </div>
              )}
              <div className={classes.form}>
                <SelectBox
                  id={"current-grade" + "-" + parentIndex}
                  defaultValue={child.currentGrade ?? ""}
                  hideNone={true}
                  title={{ name: "現在の年齢・学年", value: "currentGrade" }}
                  option={grade}
                  onChange={(e) => changeGrade(e, parentIndex)}
                ></SelectBox>
                <SelectBox
                  id={"occupation" + "-" + parentIndex}
                  defaultValue={child.desiredOccupation ?? ""}
                  hideNone={true}
                  title={{ name: "将来の夢・職業", value: "desiredOccupation" }}
                  option={occupationList ?? []}
                  onChange={(e) => changeOccupation(e, parentIndex)}
                ></SelectBox>
              </div>
              <div className={classes.addButton} onClick={addChild}>
                ＋第{parentIndex + 2}子を追加
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.submitButton} onClick={submit}>
        {props.content.submitButtonLabel ?? "診断結果を見る"}
      </div>
      <div className={classes.prev} onClick={props.prevCallback}>
        ひとつ前に戻る
      </div>
    </div>
  );
};

export default OccupationPlan;
