import React from "react";
import { CSSProperties, useEffect, useState } from "react";
import classes from "./SimpleButton.module.scss";

type ColorValiation = "orange" | "black" | "white" | "redOrange" | "green";
export type SimpleButtonProps = {
  label: string;
  onClick: () => void;
  color?: ColorValiation;
  leftIcon?: string;
  rightIcon?: string;
  maxWidth?: string;
  maxHeight?: string;
  width?: string;
  isRadius?: boolean;
  disabled?: boolean;
};

const classMap = {
  orange: classes.orange,
  black: classes.black,
  white: classes.white,
  redOrange: classes.redOrange,
  green: classes.green,
};

const SimpleButton = (props: SimpleButtonProps) => {
  const cl = classMap[props.color ?? "orange"];

  const style = (() => {
    const tmp: CSSProperties = {};

    if (props.maxWidth != null) {
      tmp.maxWidth = props.maxWidth;
    }

    if (props.maxHeight != null) {
      tmp.maxHeight = props.maxHeight;
    }

    if (props.width != null) {
      tmp.width = props.width;
    }

    if (props.isRadius) {
      tmp.borderRadius = "24rem";
    }

    return tmp;
  })();

  return (
    <button
      className={`${cl} ${props.disabled ? classes.disabled : ""}`}
      style={style}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        props.onClick();
      }}
    >
      {props.leftIcon != null && (
        <img className={classes.leftIcon} src={props.leftIcon} />
      )}
      {props.label}
      {props.rightIcon != null && (
        <img className={classes.rightIcon} src={props.rightIcon} />
      )}
    </button>
  );
};

export default SimpleButton;
