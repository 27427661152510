import React from "react";
import { useAtom } from "jotai";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import styles from "./App.module.scss";
import Footer from "./molecules/footer/Footer";
import SideMenu from "./organism/side-menu/SideMenu";
import BadgeList from "./pages/badge-list/BadgeList";
import DiagnosisTop from "./pages/diagnosis-top/DiagnosisTop";
import ErrorHandler from "./pages/error-handler/ErrorHandler";
import FamilySetting from "./pages/family-setting/FamilySetting";
import FirstSetting from "./pages/first-setting/FirstSetting";
import GoalCreate from "./pages/goal-create/GoalCreate";
import Lifeplan from "./pages/lifeplan/Lifeplan";
import Loading, { useLoading } from "./pages/loading/Loading";
import Login from "./pages/login/Login";
import MyPage from "./pages/my-page/MyPage";
import Privacy from "./pages/privacy/Privacy";
import DiagnosisResult from "./pages/diagnosis-result/DiagnosisResult";
import Serviceterm from "./pages/serviceterm/Serviceterm";
import {
  favoriteModalStateAtom,
  modalStateAtom,
} from "./state/modal/ModalAtom";
import { sideMenuAtom } from "./state/side-menu/SideMenuAtom";
import FamilySettingDetail from "./pages/family-setting-detail/FamilySettingDetail";
import EducationPlan from "./pages/education-plan/EducationPlan";
import ReplanEducation from "./pages/replan-education/ReplanEducation";
import QuizList from "./pages/quiz-list/QuizList";
import QuizDetail from "./pages/quiz-detail/QuizDetail";
import QuizAnswer from "./pages/quiz-answer/QuizAnswer";
import DiagnosisContents from "./pages/diagnosis-contents/DiagnosisContents";
import RegisterResult from "./pages/register-result/RegisterResult";
import RegisterUser from "./pages/register-user/RegisterUser";
import RegisterComplete from "./pages/register-complete/RegisterComplete";
import TokenExpire from "./pages/token-expire/TokenExpire";
import SimulateEducation from "./pages/simulate-education/SimulateEducation";
import EditEducationPlan from "./pages/edit-education-plan/EditEducationPlan";
import EditOccupationPlan from "./pages/edit-occupation-plan/EditOccupationPlan";

const App = () => {
  const [state] = useAtom(sideMenuAtom);
  const [modalState] = useAtom(modalStateAtom);
  const [favoriteModalState] = useAtom(favoriteModalStateAtom);
  const loading = useLoading();

  const containerStyle = () => {
    if (state.isShow) {
      return {
        background: "grey",
      };
    }

    if (modalState.isOpen || favoriteModalState.isOpen) {
      return {
        height: "100vh",
        overflow: "hidden",
      };
    }
    return {};
  };

  return (
    <div className={styles.appContainer} style={containerStyle()}>
      <BrowserRouter basename={"/swan"}>
        <ErrorHandler />
        <Loading />
        {state.isShow ? (
          <SideMenu></SideMenu>
        ) : (
          <Routes>
            <Route index element={<Navigate replace to={"/top"} />} />
            <Route
              path={"/top"}
              element={<DiagnosisTop isUnregistered={true} />}
            />
            <Route
              path={"/diagnosis"}
              element={<DiagnosisTop isUnregistered={false} />}
            />
            <Route
              path={"/unregistered-diagnosis-contents"}
              element={<DiagnosisContents isUnregistered={true} />}
            />
            <Route
              path={"/diagnosis-contents"}
              element={<DiagnosisContents isUnregistered={false} />}
            />
            <Route
              path={"/unregistered-diagnosis-result"}
              element={<DiagnosisResult isUnregistered={true} />}
            ></Route>
            <Route
              path={"/diagnosis-result"}
              element={<DiagnosisResult isUnregistered={false} />}
            ></Route>
            <Route path={"/login"} element={<Login />} />
            <Route path={"/first-setting"} element={<FirstSetting />}></Route>
            <Route path={"/family-setting"} element={<FamilySetting />}></Route>
            <Route
              path={"/family-setting-detail"}
              element={<FamilySettingDetail />}
            ></Route>
            <Route path={"/education-plan"} element={<EducationPlan />}></Route>
            <Route
              path={"/replan-education"}
              element={<ReplanEducation />}
            ></Route>
            {/* Ph1では一旦コメントアウトしておく */}
            {/* <Route path={"/action"} element={<Action />}></Route> */}
            {/* <Route path={"/action-detail"} element={<ActionDetail />}></Route> */}
            <Route path={"/goal-create"} element={<GoalCreate />}></Route>
            <Route path={"/lifeplan"} element={<Lifeplan />}></Route>
            <Route path={"/my-page"} element={<MyPage />}></Route>
            <Route
              path={"/register-result"}
              element={<RegisterResult />}
            ></Route>
            <Route path={"/register-user"} element={<RegisterUser />}></Route>
            <Route path={"/token-expire"} element={<TokenExpire />}></Route>
            <Route
              path={"/register-complete"}
              element={<RegisterComplete />}
            ></Route>
            <Route path={"/privacy"} element={<Privacy />}></Route>
            <Route
              path={"/simulate-education"}
              element={<SimulateEducation />}
            ></Route>
            <Route
              path={"/edit-education-plan"}
              element={<EditEducationPlan />}
            ></Route>
            <Route
              path={"/edit-occupation-plan"}
              element={<EditOccupationPlan />}
            ></Route>
            <Route path={"/serviceterm"} element={<Serviceterm />}></Route>
            <Route path={"/badge-list"} element={<BadgeList />}></Route>
            <Route path={"/quiz-list"} element={<QuizList />}></Route>
            <Route path={"/quiz-detail"} element={<QuizDetail />}></Route>
            <Route path={"/quiz-answer"} element={<QuizAnswer />}></Route>
            <Route path="*" element={<Navigate replace to={"/top"} />} />
          </Routes>
        )}
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
