import React from "react";
import { useEffect, useState } from "react";
import { AcquiredBadgeType } from "../../api/UsersActionApi";
import { fetchUser, User } from "../../api/UsersApi";
import { Badge, fetchUsersBadge } from "../../api/UsersBadgeApi";
import BadgeCard from "../../molecules/badge-card/BadgeCard";
import Header from "../../molecules/header/Header";
import BadgeResult, {
  BadgeResultData,
} from "../../organism/badge-result/BadgeResult";
import gray from "../../static/images/BadgeGrayEdit.svg";
import green from "../../static/images/BadgeGreenEdit.svg";
import rainbow from "../../static/images/BadgeRainbowEdit.svg";
import yellow from "../../static/images/BadgeYellowEdit.svg";
import greenBadge from "../../static/images/greenBadge.png";
import rainbowBadge from "../../static/images/rainbowBadge.png";
import yellowBadge from "../../static/images/yellowBadge.png";
import classes from "./BadgeList.module.scss";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";

const BadgeList = () => {
  const [user, setUser] = useState<User | null>(null);
  const [badgeList, setBadgeList] = useState<Badge[] | null>(null);
  const [data, setData] = useState<BadgeResultData | null>(null);
  const fetchUserApi = fetchUser();
  const fetchUsersBadgeApi = fetchUsersBadge();
  const [showResult, setShowResult] = useState<"hide" | "badgeInfo">("hide");

  useEffect(() => {
    fetchUserApi.fetch();
    fetchUsersBadgeApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }
    setUser(fetchUserApi.state?.data?.[0]);
  }, [fetchUserApi.state?.data]);

  useEffect(() => {
    if (fetchUsersBadgeApi.state?.data == null) {
      return;
    }
    const sortedBadgeList = fetchUsersBadgeApi.state?.data?.[0].badgeList.sort(
      (a, b) => (new Date(a.completedDate) > new Date(b.completedDate) ? -1 : 1)
    );
    setBadgeList(sortedBadgeList);
  }, [fetchUsersBadgeApi.state?.data]);

  const getBadge = (acquiredBadgeType?: AcquiredBadgeType) => {
    if (acquiredBadgeType === "green") {
      return green;
    }
    if (acquiredBadgeType === "yellow") {
      return yellow;
    }
    if (acquiredBadgeType === "rainbow") {
      return rainbow;
    }

    return gray;
  };

  const getBadgeColorName = (acquiredBadgeType?: AcquiredBadgeType) => {
    if (acquiredBadgeType === "green") {
      return "グリーン";
    }
    if (acquiredBadgeType === "yellow") {
      return "イエロー";
    }
    if (acquiredBadgeType === "rainbow") {
      return "レインボー";
    }

    return "";
  };

  const closeResult = () => {
    setShowResult("hide");
  };

  const clickBadge = (badge: Badge) => {
    // TODO: action系の方針が決まったらbadge、rankのあり方を見直す
    setData({
      badgeExplanation: badge.badgeExplanation,
      badgeImageData: badge.badgeImageData,
      badgeTitle: badge.quizName,
      quizSummaryName: badge.quizSummaryName,
      rankTitle: "",
      rankExplanation: [],
      rankImageData: "",
      id: badge.quizId,
      actionType: badge.acquiredBadgeType,
    });
    setShowResult("badgeInfo");
  };

  return (
    <>
      {showResult !== "hide" && data ? (
        <BadgeResult type={showResult} data={data} close={closeResult} />
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.headerArea}>
              <Header />
            </div>
            <div className={classes.title}>バッジリスト</div>
            <div className={classes.badgeArea}>
              <div className={classes.badgeCircle}>
                <img
                  src={rainbowBadge}
                  alt="badge"
                  className={classes.badgeImg}
                />
                <div
                  className={classes.inCircle}
                  style={{ backgroundColor: "#FFF7D4" }}
                >
                  {user?.numberOfCompletedRainbowbadge}
                </div>
              </div>
              <div className={classes.badgeCircle}>
                <img
                  src={yellowBadge}
                  alt="badge"
                  className={classes.badgeImg}
                />
                <div
                  className={classes.inCircle}
                  style={{ backgroundColor: "#FFF7D4" }}
                >
                  {user?.numberOfCompletedYellowbadge}
                </div>
              </div>
              <div className={classes.badgeCircle}>
                <img
                  src={greenBadge}
                  alt="badge"
                  className={classes.badgeImg}
                />
                <div
                  className={classes.inCircle}
                  style={{ backgroundColor: "#E1F8F3" }}
                >
                  {user?.numberOfCompletedGreenbadge}
                </div>
              </div>
            </div>
            <div className={classes.cardArea}>
              {badgeList?.map((badge) => (
                <div
                  key={badge.quizId}
                  className={classes.cardWrapper}
                  onClick={() => clickBadge(badge)}
                >
                  <BadgeCard
                    image={badge.badgeImageData}
                    content={badge.quizName}
                  ></BadgeCard>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.breadCrumb}>
            <BreadCrumb
              data={[
                { displayName: "みらいいコンシェル", url: "/my-page" },
                { displayName: "バッジリスト", url: "/badge-list" },
              ]}
            />
          </div>
        </>
      )}
    </>
  );
};

export default BadgeList;
