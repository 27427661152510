import { atom } from "jotai";
import { State } from "./State";
import { fetchBase, postBase } from "./BaseApi";

export type QuizType = "education" | "financial";

export type QuizCategory = {
  isLocked: boolean;
  quizList: Quiz[];
};

export type Quiz = {
  categoryId: string;
  categorySequence: number;
  quizClassification: string;
  quizClassificationName: string;
  quizCategoryName: string;
  quizSummaryName: string;
  quizName: string;
  quizId: string;
  completedDate: string;
  acquiredBadgeType: string;
  badgeImageData: string;
};

export type UsersQuiz = {
  userId: string;
  quizCategoryList: QuizCategory[];
};

export type UsersQuizResult = {
  quizId: string;
  isAcquiredBadgeType: true;
  badgeTitle: string;
  acquiredBadgeType: string;
  badgeExplanation: string;
  badgeImageData: string;
  supportLink: string;
};

type UsersQuizParam = {
  quizClassification: QuizType;
};

type PostUsersQuizParam = {
  quizId: string;
  totalCorrectAnswer: number;
};

const uri = "api/users/quiz";

export const fetchUserQuizAtom = atom<State<UsersQuiz> | null>(null);

export const fetchUsersQuiz = () => {
  return fetchBase<UsersQuiz, UsersQuizParam>(uri, fetchUserQuizAtom);
};

export const postUserQuizAtom = atom<State<UsersQuizResult> | null>(null);

export const postUsersQuiz = () => {
  return postBase<UsersQuizResult, PostUsersQuizParam>(uri, postUserQuizAtom);
};
