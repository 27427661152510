import React, { useEffect, useState } from "react";
import classes from "./EditEducationPlan.module.scss";
import EducationPlan from "../../organism/education-plan/EducationPlan";
import { useNavigate } from "react-router-dom";
import {
  ChildEducation,
  fetchUsersEducationPath,
  postUsersEducationFee,
} from "../../api/UsersEducationApi";
import schoolLogo from "../../static/images/school.png";
import Header from "../../molecules/header/Header";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";

const EditEducationPlan = () => {
  const navigate = useNavigate();
  const postEducationPlan = postUsersEducationFee();
  const fetchUsersEducationPathApi = fetchUsersEducationPath();
  const [submitState, setSubmitState] = useState<boolean>(false);

  useEffect(() => {
    fetchUsersEducationPathApi.fetch();
  }, []);
  const submit = (children: ChildEducation[]) => {
    postEducationPlan.post({ childrenEducation: children });
    setSubmitState(true);
  };

  useEffect(() => {
    if (submitState && postEducationPlan.state?.status === "success") {
      navigate("/simulate-education");
    }
  }, [submitState, postEducationPlan]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.body}>
        <EducationPlan
          content={{
            header: "教育資金シミュレーション",
            title: ["進学コース"],
            logo: schoolLogo,
            teacherComment: [
              "お子さまの将来を思い浮かべてください♪お子さまにどんな学校へ進学してほしいですか？",
            ],
            children:
              fetchUsersEducationPathApi.state?.data?.[0].userEducationPath,
            submitButtonLabel: "教育資金を計算する",
          }}
          prevCallback={() => navigate("/simulate-education")}
          submitCallback={submit}
        />
      </div>
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル", url: "/my-page" },
          {
            displayName: "教育資金シミュレーション",
            url: "/simulate-education",
          },
          { displayName: "進学コース選択" },
        ]}
      />
    </div>
  );
};

export default EditEducationPlan;
