import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";

export type UsersEducationFee = {
  totalTuitionFee: number;
  totalTuitionFeeYearlyBased: number;
  totalTuitionFeeMonthlyBased: number;
};

export type ChildEducation = {
  currentGrade: string;
  kindergarden: string;
  primarySchool: string;
  juniorSchool: string;
  highSchool: string;
  university: string;
  desiredOccupation: string;
};

export type CalculateUnregisteredUsersEducationFeeParam = {
  childrenEducation: ChildEducation[];
  isRegistered: false;
};

export type PostUnregisteredUsersEducationFeeParam = {
  childrenEducation: ChildEducation[];
  isRegistered: true;
  emailAddress: string;
};

export type PostDesiredOccupationParam = {
  childrenEducation: ChildEducation[];
  isRegistered: true;
};

export type PostUsersEducationFeeParam = {
  childrenEducation: ChildEducation[];
};

export type UsersEducationPath = {
  userEducationPath: ChildEducation[];
};

const uri = "api/users/education-fee";
const unregisteredUri = "api/unregistered-users/education-fee";
const pathUri = "api/users/education-path";

export const fetchUsersEducationFeeAtom = atom<State<UsersEducationFee> | null>(
  null
);
export const fetchUsersEducationFee = () => {
  return fetchBase<UsersEducationFee>(uri, fetchUsersEducationFeeAtom);
};

// 進学先の取得
export const fetchUsersEducationPathAtom =
  atom<State<UsersEducationPath> | null>(null);
export const fetchUsersEducationPath = () => {
  return fetchBase<UsersEducationPath>(pathUri, fetchUsersEducationPathAtom);
};

// 教育費更新
export const postUsersEducationFeeAtom = atom<State<UsersEducationFee> | null>(
  null
);
export const postUsersEducationFee = () => {
  return postBase<UsersEducationFee, PostUsersEducationFeeParam>(
    uri,
    postUsersEducationFeeAtom
  );
};

// なりたい職業更新
export const postDesiredOccupation = () => {
  return postBase<UsersEducationFee, PostDesiredOccupationParam>(
    uri,
    postUsersEducationFeeAtom
  );
};

// 仮登録
export const postUnregisteredUsersEducationFeeAtom =
  atom<State<UsersEducationFee> | null>(null);
export const postUnregisteredUsersEducationFee = () => {
  return postBase<UsersEducationFee, PostUnregisteredUsersEducationFeeParam>(
    unregisteredUri,
    postUnregisteredUsersEducationFeeAtom
  );
};

// 仮計算
export const calculateUnregisteredUsersEducationFeeAtom =
  atom<State<UsersEducationFee> | null>(null);
export const calculateUnregisteredUsersEducationFee = () => {
  return postBase<
    UsersEducationFee,
    CalculateUnregisteredUsersEducationFeeParam
  >(unregisteredUri, calculateUnregisteredUsersEducationFeeAtom);
};
