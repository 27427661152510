import { atom } from "jotai";
import { fetchBase } from "./BaseApi";
import { State } from "./State";

export type EducationTimeline = {
  childName: string;
  eventName: string;
  occupationName?: string;
  averageSalary?: number;
  occupationImageData?: string;
};

export type UsersEducationTimeline = {
  [year: string]: EducationTimeline[];
};

export const fetchUsersEducationTimelineAtom =
  atom<State<UsersEducationTimeline> | null>(null);
export const fetchUsersEducationTimeline = () => {
  const uri = "api/users/education-timeline";
  return fetchBase<UsersEducationTimeline>(
    uri,
    fetchUsersEducationTimelineAtom
  );
};
