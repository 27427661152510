import React, { useEffect, useState } from "react";
import classes from "./RegisterUser.module.scss";
import headerImage from "../../static/images/header_icon.png";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import { validators } from "../../common/utility/validator.util";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import eyeIcon from "../../static/images/iconOpenEye.svg";
import closeEyeIcon from "../../static/images/iconCloseEye.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserConfirm } from "../../api/UsersConfirmApi";
import { postUserRegister } from "../../api/UsersRegisterApi";

const RegisterUser = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>();
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [eye, setEye] = useState<string>(closeEyeIcon);
  const [passwordType, setPasswordType] = useState<"password" | "text">(
    "password"
  );

  const userConfirmApi = fetchUserConfirm();
  const userRegisterApi = postUserRegister();

  useEffect(() => {
    if (token == null) {
      navigate("/top");
    } else {
      userConfirmApi.fetch({ token });
    }
  }, []);

  useEffect(() => {
    if (userConfirmApi?.state?.status === "error") {
      navigate("/token-expire");
    }

    if (userConfirmApi?.state?.status === "success") {
      console.log(userConfirmApi);
      setUserId(userConfirmApi?.state?.data?.[0].userId);
    }
  }, [userConfirmApi]);

  const nameValidator = (value: string) => {
    return validators.required(value);
  };

  const passwordValidator = (value: string) => {
    const requiredResult = validators.required(value);

    if (!requiredResult.status) {
      return requiredResult;
    }

    const passwordResult = validators.password(value);

    if (!passwordResult.status) {
      return passwordResult;
    }

    return { status: true };
  };

  const register = () => {
    if (userId == null) {
      navigate("/expire-token");
    } else {
      userRegisterApi.post({
        userId,
        userName: name,
        password,
      });
    }
  };

  useEffect(() => {
    if (userRegisterApi.state?.status === "success") {
      navigate("/register-complete");
    }
  }, [userRegisterApi]);

  return (
    <div className={classes.container}>
      <img src={headerImage} alt={headerImage} />
      <div className={classes.body}>
        <div className={classes.title}>本登録</div>
        <div className={classes.form}>
          <TextForm
            label="氏名"
            type="text"
            name={"name"}
            value={name}
            color="white"
            onChange={(args: TextFormResult) => setName(args.value)}
            validator={nameValidator}
          />
        </div>

        <div className={classes.form}>
          <TextForm
            label="パスワード"
            type={passwordType}
            name={"password"}
            value={password}
            color="white"
            rightIcon={eye}
            onClickRightButton={() => {
              if (eye === eyeIcon) {
                setEye(closeEyeIcon);
              } else {
                setEye(eyeIcon);
              }

              if (passwordType === "password") {
                setPasswordType("text");
              } else {
                setPasswordType("password");
              }
            }}
            onChange={(args: TextFormResult) => setPassword(args.value)}
            validator={passwordValidator}
          />
        </div>

        <div className={classes.button}>
          <SimpleButton
            label="本登録"
            width="100%"
            color="orange"
            isRadius={true}
            onClick={register}
            disabled={
              !passwordValidator(password).status || !nameValidator(name).status
            }
          ></SimpleButton>
        </div>
      </div>
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル" },
          { displayName: "本登録" },
        ]}
      />
    </div>
  );
};

export default RegisterUser;
