import React, { useEffect, useState } from "react";
import classes from "./QuizAccordion.module.scss";
import ActionCard, { ActionCardType } from "../../atoms/action-card/ActionCard";
import closeIcon from "../../static/images/minus.svg";
import openIcon from "../../static/images/plus.svg";
import { RankCategory } from "../../api/UsersActionApi";
import { Quiz, QuizCategory } from "../../api/UsersQuiz";
import { useNavigate } from "react-router-dom";
import badgeGrayEdit from "../../static/images/BadgeGrayEdit.svg";
import CompleteIcon from "../../static/images/complete-icon.svg";

export type QuizAccordionProps = {
  quiz: Quiz[];
  isLocked: boolean;
  isCompleted: boolean;
  index: number;
};

type DisplayList = QuizAccordionProps & {
  expand: boolean;
};

const QuizAccordion = (props: QuizAccordionProps) => {
  const [displayList, setDisplayList] = useState<DisplayList>({
    ...props,
    expand: false,
  });

  const click = () => {
    setDisplayList((prev) => ({
      ...prev,
      expand: !prev.expand,
    }));
  };
  const navigate = useNavigate();

  useEffect(() => {
    setDisplayList({
      ...props,
      expand: false,
    });
  }, [props]);
  return (
    <div className={classes.container}>
      <div className={classes.parentContainer} onClick={click}>
        <div className={classes.label}>
          {displayList.quiz[0].quizCategoryName}
        </div>
        <div className={classes.right}>
          {displayList.isCompleted ? (
            <img
              src={CompleteIcon}
              alt={CompleteIcon}
              className={classes.completeIcon}
            />
          ) : (
            <>
              {props.isLocked && (
                <div className={classes.message}>
                  <div>上のテーマのバッジを</div>
                  <div>全て集めるとオープン！</div>
                </div>
              )}
            </>
          )}
          {displayList?.expand ? (
            <img className={classes.icon} src={closeIcon} alt={closeIcon} />
          ) : (
            <img className={classes.icon} src={openIcon} alt={openIcon} />
          )}
        </div>
      </div>
      {displayList?.expand && (
        <div className={classes.childrenContainer}>
          {displayList?.quiz.map((c, index) => {
            return (
              <div
                className={classes.childContainer}
                key={`${index}-${c.quizId}`}
              >
                <ActionCard
                  data={{
                    label: c.quizSummaryName,
                    title: c.quizName,
                    completedDate: c.completedDate,
                    badge: c.badgeImageData ?? badgeGrayEdit,
                    disabled: displayList.isLocked,
                    onClick: () => {
                      navigate(`/quiz-detail?quizId=${c.quizId}`, {
                        state: c,
                      });
                    },
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default QuizAccordion;
