import React from "react";
import classes from "./DiagnosisSlider.module.scss";

type Props = {
  currentNo: number;
  totalNo: number;
};

const DiagnosisSlider = (props: Props) => {
  const calcCurrentStyle = () => {
    const left = (100 / props.totalNo) * (props.currentNo - 1);
    return { left: `${left}%` };
  };

  const calcBarDoneStyle = () => {
    const width = (100 / props.totalNo) * (props.currentNo - 1);
    return { width: `${width}%` };
  };

  return (
    <div className={classes.content}>
      <div className={classes.current} style={calcCurrentStyle()}>
        {props.currentNo}
      </div>
      <div className={classes.barDone} style={calcBarDoneStyle()}></div>
      <div className={classes.barYet}></div>
      <div className={classes.total}>{props.totalNo}</div>
    </div>
  );
};

export default DiagnosisSlider;
