import React, { useEffect, useRef, useState } from "react";
import classes from "./QuizAnswer.module.scss";
import Header from "../../molecules/header/Header";
import QuizHeader from "../../molecules/quiz-header/QuizHeader";
import { fetchQuiz, QuizContent } from "../../api/QuizApi";
import Quiz from "../../molecules/quiz/Quiz";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import { postUsersQuiz } from "../../api/UsersQuiz";
import { useSearchParams } from "react-router-dom";
import QuizBadgeResult, {
  QuizBadgeResultData,
} from "../../organism/quiz-badge-result/QuizBadgeResult";

const QuizAnswer = () => {
  const [quiz, setQuiz] = useState<QuizContent[] | null>(null);

  //ProgressBar
  const progressContainerRef = useRef<HTMLDivElement | null>(null);
  const [progress, setProgress] = useState(1);
  const [startNumberPosition, setStartNumberPosition] = useState({});
  const [currentNo, setCurrentNo] = useState(1);
  const [displayNext, setDisplayNext] = useState(false);
  const [quizParams, setQuizParams] = useState<
    { quizNumber: number; isCorrect: boolean }[]
  >([]);
  const [showResult, setShowResult] = useState<"hide" | "badge" | "rankup">(
    "hide"
  );
  const [quizResultData, setQuizResultData] =
    useState<QuizBadgeResultData | null>(null);

  const fetchQuizApi = fetchQuiz();
  const postQuizApi = postUsersQuiz();
  const MAX_PROGRESS = 98;
  const [searchParams] = useSearchParams();
  const quizId = searchParams.get("quizId");

  const init = () => {
    setProgress(1);
    setCurrentNo(1);
    setDisplayNext(false);
    setQuizParams([]);
    setShowResult("hide");
  };

  useEffect(() => {
    if (quizId == null) {
      return;
    }
    fetchQuizApi.fetch({ quizId });
  }, []);

  useEffect(() => {
    if (showResult === "hide") {
      init();
    }
  }, [showResult]);

  useEffect(() => {
    if (fetchQuizApi.state?.data == null) {
      return;
    }
    setQuiz(fetchQuizApi.state.data[0].quizList);
  }, [fetchQuizApi.state?.data]);

  useEffect(() => {
    if (quiz == null) {
      return;
    }
    //プログレスバー更新
    currentNo === 1
      ? setProgress(0)
      : setProgress((currentNo / quiz?.length) * MAX_PROGRESS);
  }, [currentNo]);

  useEffect(() => {
    const progressContainer = progressContainerRef.current;
    if (progressContainer) {
      const progressContainerWidth = progressContainer.offsetWidth;
      const newLeftValue = (progress / 100) * progressContainerWidth - 12;
      setStartNumberPosition({
        left: `${newLeftValue}px`,
      });
    }
  }, [progress]);

  useEffect(() => {
    if (postQuizApi.state?.data == null) {
      return;
    }
    setQuizResultData(postQuizApi.state.data[0]);
    setShowResult("badge");
  }, [postQuizApi.state?.data]);

  const setQuizResult = (quizNumber: number, isCorrect: boolean) => {
    setQuizParams([...quizParams, { quizNumber, isCorrect }]);
    setDisplayNext(true);
  };

  const next = () => {
    setCurrentNo(currentNo + 1);
    setDisplayNext(false);
  };

  const finish = () => {
    if (quizId == null) {
      return;
    }
    const totalCorrectAnswer = quizParams.filter((q) => q.isCorrect).length;
    postQuizApi.post({
      quizId,
      totalCorrectAnswer: totalCorrectAnswer,
    });
  };

  const close = () => {
    postQuizApi.reset();
    setShowResult("hide");
  };

  return (
    <>
      {showResult !== "hide" && quizResultData != null ? (
        <QuizBadgeResult
          data={{
            quizId: quizResultData.quizId,
            badgeImageData: quizResultData.badgeImageData,
            badgeTitle: quizResultData.badgeTitle,
            badgeExplanation: quizResultData.badgeExplanation,
            isAcquiredBadgeType: quizResultData.isAcquiredBadgeType,
            supportLink: quizResultData.supportLink,
          }}
          close={close}
        />
      ) : (
        <div className={classes.container}>
          <Header />
          <QuizHeader title="◯×クイズ" />
          <div className={classes.progressBarContainer}>
            <div
              className={classes.progressContainer}
              ref={progressContainerRef}
            >
              <div
                className={classes.progressBar}
                style={{ width: `${progress}%` }}
              ></div>
              <span className={classes.startNumber} style={startNumberPosition}>
                {currentNo}
              </span>
              <span className={classes.endNumber}>{quiz?.length}</span>
            </div>
          </div>
          {quiz != null && (
            <Quiz
              data={quiz[currentNo - 1]}
              index={currentNo}
              total={quiz.length}
              submit={(result) => setQuizResult(currentNo, result)}
            />
          )}
          {displayNext && (
            <div className={classes.button}>
              {currentNo !== quiz?.length ? (
                <SimpleButton
                  label="つぎの問題"
                  onClick={next}
                  width="343rem"
                />
              ) : (
                <SimpleButton
                  label="結果発表"
                  onClick={finish}
                  width="343rem"
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default QuizAnswer;
