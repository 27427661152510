import { atom } from "jotai";
import { fetchBase } from "./BaseApi";
import { State } from "./State";

const uri = "api/users/badge";

export type UsersBadge = {
  badgeList: Badge[];
  userId: string;
};

export type Badge = {
  quizId: string;
  quizSummaryName: string;
  quizName: string;
  completedDate: string;
  acquiredBadgeType: string;
  badgeImageData: string;
  badgeExplanation: string;
};

export const fetchUsersBadgeAtom = atom<State<UsersBadge> | null>(null);

export const fetchUsersBadge = () => {
  return fetchBase<UsersBadge>(uri, fetchUsersBadgeAtom);
};
