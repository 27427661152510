import React from "react";
import classes from "./RegisterResult.module.scss";
import headerImage from "../../static/images/DiagnosisQuestionHeader.png";
import teacherImage from "../../static/images/teacher-mail.svg";
import { useAtom } from "jotai";
import { mailAtom } from "../diagnosis-result/DiagnosisResult";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { useNavigate } from "react-router-dom";

const RegisterResult = () => {
  const [mail, setMail] = useAtom(mailAtom);
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.container}>
        <img
          className={classes.headerImage}
          src={headerImage}
          alt={headerImage}
        />
        <img
          className={classes.teacherImage}
          src={teacherImage}
          alt={teacherImage}
        />
        <div className={classes.title}>メールを送信しました</div>
        <div className={classes.email}>{mail}</div>
        <div className={classes.text}>
          に、「みらいいコンシェル{" "}
          <span className={classes.mailAddress}>
            &lt;support@miraii-concierge.jp&gt;
          </span>
          」からご案内のメールをお送りしました。
          数分経っても届かない場合は、迷惑メールフォルダをご確認ください。
        </div>
        <div className={classes.captionTitle}>メールが届きませんか？</div>
        <div className={classes.caption}>
          入力いただいたメールアドレスが登録されていないか、
          登録いただいた際にメールアドレスが誤っていた可能性がございます。
          お手数ですが
          <span className={classes.link} onClick={() => navigate("/top")}>
            こちらのリンク
          </span>
          から再度お試しください。
        </div>
      </div>
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル" },
          { displayName: "子育てとおカネの9タイプ診断結果" },
          { displayName: "メール送信完了" },
        ]}
      />
    </>
  );
};

export default RegisterResult;
