import React from "react";
import classes from "./QuizHeader.module.scss";

type QuizHeaderProps = {
  title: string;
};

const QuizHeader = (props: QuizHeaderProps) => {
  return (
    <div className={classes.quizHeader}>
      <div>{props.title}</div>
    </div>
  );
};

export default QuizHeader;
