import React, { useEffect, useState } from "react";
import classes from "./QuizList.module.scss";
import Header from "../../molecules/header/Header";
import PageTitle from "../../atoms/page-title/PageTitle";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-normal.svg";
import {
  fetchUsersQuiz,
  Quiz,
  QuizCategory,
  QuizType,
} from "../../api/UsersQuiz";

import QuizAccordion from "../../organism/quiz-accordion/QuizAccordion";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";

const QuizList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const tabType = searchParams.get("type");
  const getClass = (actionType: QuizType) => {
    if (activeTab === actionType) {
      return `${classes.tab} ${classes.active}`;
    }

    return `${classes.tab}`;
  };
  const fetchUserQuizApi = fetchUsersQuiz();
  const [activeTab, setActiveTab] = useState<QuizType>("education");
  const [quizCategory, setQuizCategory] = useState<QuizCategory[]>();

  const selectTab = (actionType: QuizType) => {
    navigate(`/quiz-list?type=${actionType}`);
  };

  const callApi = (tab: QuizType) => {
    fetchUserQuizApi.fetch({ quizClassification: tab });
  };

  useEffect(() => {
    const tab = (tabType as QuizType) ?? "education";
    setActiveTab(tab);
    callApi(tab);
  }, []);

  useEffect(() => {
    const tab = (tabType as QuizType) ?? "education";
    setActiveTab(tab);
    callApi(tab);
  }, [location]);

  useEffect(() => {
    if (fetchUserQuizApi.state?.data == null) {
      return;
    }
    setQuizCategory(fetchUserQuizApi.state.data[0].quizCategoryList);
  }, [fetchUserQuizApi.state?.data]);

  const checkCompolete = (quizList: Quiz[]) => {
    return quizList.every((quiz) => quiz.completedDate !== null);
  };

  return (
    <div className={classes.container}>
      <Header />
      <PageTitle title="◯×クイズ" />
      <div className={classes.tabList}>
        <div
          className={getClass("education")}
          onClick={() => {
            selectTab("education");
          }}
        >
          子育て
        </div>
        <div
          className={getClass("financial")}
          onClick={() => {
            selectTab("financial");
          }}
        >
          おカネ
        </div>
      </div>
      <div className={classes.teacherCommentWrapper}>
        <TeacherComment
          image={teacherImage}
          comment={[
            "クイズに回答すると正解数に応じたバッジがもらえます！全て集めるとつぎのテーマのクイズがオープン！がんばってください♪",
          ]}
        ></TeacherComment>
      </div>
      <div className={classes.cardArea}>
        {quizCategory?.map((q, index) => {
          return (
            <div
              key={`${index}-${q.quizList[0].quizId}`}
              className={classes.card}
            >
              <QuizAccordion
                quiz={q.quizList}
                isLocked={q.isLocked}
                isCompleted={checkCompolete(q.quizList)}
                index={index}
              />
            </div>
          );
        })}
      </div>
      <div className={classes.breadCrumb}>
        <BreadCrumb
          data={[
            { displayName: "みらいいコンシェル", url: "/my-page" },
            { displayName: "◯×クイズ一覧", url: "/quiz-list" },
          ]}
        />
      </div>
    </div>
  );
};

export default QuizList;
