import React, { useEffect } from "react";
import classes from "./SimulateEducation.module.scss";
import { fetchUsersEducationTimeline } from "../../api/UsersEducationTimeline";
import FrameTemplate from "../../organism/frame-template/FrameTemplate";
import { useNavigate } from "react-router-dom";
import dreamImage from "../../static/images/dream.png";
import schoolImage from "../../static/images/school.png";
import Header from "../../molecules/header/Header";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-ok.svg";
import {
  fetchUsersEducationFee,
  fetchUsersEducationPath,
} from "../../api/UsersEducationApi";
import EducationFeeCard from "../../molecules/education-fee-card/EducationFeeCard";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import EducationTimeline from "../../organism/education-timeline/EducationTimeline";

const SimulateEducation = () => {
  const fetchEducationTimelineApi = fetchUsersEducationTimeline();
  const fetchEducationFeeApi = fetchUsersEducationFee();
  useEffect(() => {
    fetchEducationTimelineApi.fetch();
    fetchEducationFeeApi.fetch();
  }, []);

  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.body}>
        <div className={classes.title}>教育資金シミュレーション</div>
        <TeacherComment
          image={teacherImage}
          comment={[
            "これからお子さまに必要な教育資金をシミュレーションしてみましょう♪以下の2つの方法から選択できます！",
          ]}
        />
        <div className={classes.courseContainer}>
          <div className={classes.card}>
            <FrameTemplate
              frameTitle={["将来の夢・職業から", "計算してみる"]}
              onClick={() => navigate("/edit-occupation-plan")}
            >
              <img
                className={classes.image}
                src={dreamImage}
                alt={dreamImage}
              />
            </FrameTemplate>
          </div>
          <div className={classes.card}>
            <FrameTemplate
              frameTitle={["進学コースから", "計算してみる"]}
              onClick={() => navigate("/edit-education-plan")}
            >
              <img
                className={classes.image}
                src={schoolImage}
                alt={schoolImage}
              />
            </FrameTemplate>
          </div>
        </div>
        <div className={classes.caption}>現在のシミュレーション値</div>
        <EducationFeeCard
          color={"blue"}
          titleSize={16}
          totalTuitionFee={
            fetchEducationFeeApi.state?.data?.[0].totalTuitionFee
          }
          totalTuitionFeeMonthlyBased={
            fetchEducationFeeApi.state?.data?.[0].totalTuitionFeeMonthlyBased
          }
          totalTuitionFeeYearlyBased={
            fetchEducationFeeApi.state?.data?.[0].totalTuitionFeeYearlyBased
          }
        />
        <div className={classes.timelineContainer}>
          <EducationTimeline
            educationTimeline={fetchEducationTimelineApi.state?.data?.[0]}
          />
        </div>
      </div>
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル", url: "/my-page" },
          { displayName: "教育資金シミュレーション" },
        ]}
      />
    </div>
  );
};

export default SimulateEducation;
