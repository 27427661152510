import React, { useEffect, useState } from "react";
import classes from "./EducationTimeline.module.scss";
import {
  EducationTimeline as EducationTimelineType,
  UsersEducationTimeline,
} from "../../api/UsersEducationTimeline";
import { fetchUsersEducationPath } from "../../api/UsersEducationApi";
import { addComma } from "../../common/utility/number.util";

type Props = {
  educationTimeline?: UsersEducationTimeline;
};

type ChildNameToColor = { [key: string]: { backgroundColor: string } };

const EducationTimeline = (props: Props) => {
  const colorList = [
    "#EE8699",
    "#D08FEF",
    "#EB5A3C",
    "#16404D",
    "#344CB7",
    "#16C47F",
    "#3E7B27",
    "#213555",
    "#543A14",
  ];

  const [childNameStyle, setChildNameStyle] = useState<ChildNameToColor>();
  const fetchEducationPathApi = fetchUsersEducationPath();

  useEffect(() => {
    fetchEducationPathApi.fetch();
  }, []);

  useEffect(() => {
    if (props.educationTimeline == null) {
      return;
    }

    const childNameList: string[] = [];
    Object.values(props.educationTimeline).forEach((value) => {
      value.forEach((e) => {
        childNameList.push(e.childName);
      });
    });

    const childNameToColor: ChildNameToColor = {};
    const childNameUniqueList = Array.from(new Set(childNameList));
    childNameUniqueList.sort();

    childNameUniqueList.forEach((name, index) => {
      if (index >= colorList.length) {
        childNameToColor[name] = { backgroundColor: colorList[0] };
      } else {
        childNameToColor[name] = { backgroundColor: colorList[index] };
      }
    });

    setChildNameStyle(childNameToColor);
  }, [props.educationTimeline]);

  const isOccupation = (elem: EducationTimelineType) => {
    if (elem.occupationName != null) {
      return true;
    }

    return false;
  };
  return (
    <>
      {props.educationTimeline != null && (
        <div className={classes.container}>
          <div className={classes.title}>子どものタイムライン</div>
          <div className={classes.caption}>
            <div className={classes.text}>
              {fetchEducationPathApi.state?.data?.[0].userEducationPath?.[0]
                .desiredOccupation != null &&
              fetchEducationPathApi.state?.data?.[0].userEducationPath?.[0]
                .desiredOccupation != ""
                ? "将来の夢・職業"
                : "進学コース"}
            </div>
          </div>
          <div className={classes.body}>
            {Object.entries(props.educationTimeline).map(
              (keyValue, parentIndex) => {
                return (
                  <div key={keyValue[0]} className={classes.block}>
                    <div
                      className={
                        isOccupation(keyValue[1][0])
                          ? classes.occupationPoint
                          : classes.point
                      }
                    ></div>
                    <div
                      className={
                        isOccupation(keyValue[1][0])
                          ? classes.occupationYear
                          : classes.year
                      }
                    >
                      {keyValue[0]}
                    </div>
                    <div className={classes.eventBlock}>
                      {keyValue[1].map((elem, childIndex) => {
                        return (
                          <>
                            {isOccupation(elem) ? (
                              <div
                                key={`${parentIndex}-${childIndex}`}
                                className={classes.occupationContents}
                              >
                                <div
                                  className={classes.childName}
                                  style={childNameStyle?.[elem.childName] ?? {}}
                                >
                                  {elem.childName}
                                </div>
                                <div className={classes.occupationEvent}>
                                  <div className={classes.eventName}>
                                    {elem.eventName}
                                  </div>
                                  <div className={classes.occupationName}>
                                    {elem.occupationName}
                                  </div>
                                  <div className={classes.averageSalary}>
                                    平均年収{addComma(elem.averageSalary)}万円
                                  </div>
                                </div>
                                <img
                                  className={classes.occupationIcon}
                                  src={elem.occupationImageData}
                                  alt={elem.occupationImageData}
                                />
                              </div>
                            ) : (
                              <div
                                key={`${parentIndex}-${childIndex}`}
                                className={classes.contents}
                              >
                                <div
                                  className={classes.childName}
                                  style={childNameStyle?.[elem.childName] ?? {}}
                                >
                                  {elem.childName}
                                </div>
                                <div className={classes.event}>
                                  {elem.eventName}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EducationTimeline;
