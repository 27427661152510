import logo1 from "../../static/images/questionLogo9.png";
import logo2 from "../../static/images/questionLogo10.png";
import logo3 from "../../static/images/questionLogo11.png";
import logo4 from "../../static/images/questionLogo12.png";
import logo5 from "../../static/images/questionLogo13.png";
import logo6 from "../../static/images/questionLogo14.png";
import logo7 from "../../static/images/questionLogo15.png";
import logo8 from "../../static/images/questionLogo1.png";
import logo9 from "../../static/images/questionLogo2.png";
import logo10 from "../../static/images/questionLogo3.png";
import logo11 from "../../static/images/questionLogo4.png";
import logo12 from "../../static/images/questionLogo5.png";
import logo13 from "../../static/images/questionLogo6.png";
import logo14 from "../../static/images/questionLogo7.png";
import logo15 from "../../static/images/questionLogo8.png";

import { DiagnosisQuestion } from "../../organism/diagnosis-question/DiagnosisQuestion";

export const diagnosisQuestionList: DiagnosisQuestion[] = [
  {
    logo: logo1,
    questionNo: 1,
    question: ["お金について", "子どもと話すこと（話す予定）はある？"],
    answerList: [
      {
        answerNo: 1,
        answer: "週1回くらい",
      },
      {
        answerNo: 2,
        answer: "月1回くらい",
      },
      {
        answerNo: 3,
        answer: "年1回くらい",
      },
      {
        answerNo: 4,
        answer: "あまり話せていない",
      },
    ],
  },
  {
    logo: logo2,
    questionNo: 2,
    question: [
      "将来の夢や目標について",
      "子どもと話すこと（話す予定）はある？",
    ],
    answerList: [
      {
        answerNo: 1,
        answer: "週1回くらい",
      },
      {
        answerNo: 2,
        answer: "月1回くらい",
      },
      {
        answerNo: 3,
        answer: "年1回くらい",
      },
      {
        answerNo: 4,
        answer: "あまり話せていない",
      },
    ],
  },
  {
    logo: logo3,
    questionNo: 3,
    question: ["子どものおこづかい", "どうしてる（どうする予定）？"],
    answerList: [
      {
        answerNo: 1,
        answer: "定額制",
      },
      {
        answerNo: 2,
        answer: "報酬制",
      },
      {
        answerNo: 3,
        answer: "都度払い",
      },
      {
        answerNo: 4,
        answer: "その他",
      },
    ],
  },
  {
    logo: logo4,
    questionNo: 4,
    question: ["お金の使い方のルールを", "決めている？"],
    answerList: [
      {
        answerNo: 1,
        answer: "家族全員ルールを決めている",
      },
      {
        answerNo: 2,
        answer: "親だけルールを決めている",
      },
      {
        answerNo: 3,
        answer: "子どもだけルールを決めている（決める予定）",
      },
      {
        answerNo: 4,
        answer: "特にルールは決めていない",
      },
    ],
  },
  {
    logo: logo5,
    questionNo: 5,
    question: ["家族とお金の話をする時は", "どんな感じ？"],
    answerList: [
      {
        answerNo: 1,
        answer: "いつも通り",
      },
      {
        answerNo: 2,
        answer: "ワクワクする",
      },
      {
        answerNo: 3,
        answer: "ドキドキする",
      },
      {
        answerNo: 4,
        answer: "イライラする",
      },
    ],
  },
  {
    logo: logo6,
    questionNo: 6,
    question: ["子どもにおこづかいを渡す", "目的は？"],
    answerList: [
      {
        answerNo: 1,
        answer: "頑張ったご褒美をあげたい",
      },
      {
        answerNo: 2,
        answer: "必要なものは自分で買ってほしい",
      },
      {
        answerNo: 3,
        answer: "貯金の習慣を身につけてほしい",
      },
      {
        answerNo: 4,
        answer: "特に目的はない",
      },
    ],
  },
  {
    logo: logo7,
    questionNo: 7,
    question: [
      "子どもから高額商品のおねだり！",
      "買う／買わないは誰が決める？",
    ],
    answerList: [
      {
        answerNo: 1,
        answer: "自分ひとりで決める",
      },
      {
        answerNo: 2,
        answer: "大人だけで話し合って決める",
      },
      {
        answerNo: 3,
        answer: "自分と子どもで話し合って決める（決めたい）",
      },
      {
        answerNo: 4,
        answer: "家族全員で話し合って決める",
      },
    ],
  },

  {
    logo: logo8,
    questionNo: 8,
    question: ["お金の知識の中でも", "特に必要だと感じるのはどれ？"],
    answerList: [
      {
        answerNo: 1,
        answer: "節約",
      },
      {
        answerNo: 2,
        answer: "貯金",
      },
      {
        answerNo: 3,
        answer: "保険",
      },
      {
        answerNo: 4,
        answer: "投資",
      },
    ],
  },
  {
    logo: logo9,
    questionNo: 9,
    question: ["宝くじで100万円当たったら", "どうする？"],
    answerList: [
      {
        answerNo: 1,
        answer: "パーッと使っちゃう",
      },
      {
        answerNo: 2,
        answer: "とりあえず貯金する",
      },
      {
        answerNo: 3,
        answer: "貯金しつつ運用もする",
      },
      {
        answerNo: 4,
        answer: "将来のため全て運用する",
      },
    ],
  },
  {
    logo: logo10,
    questionNo: 10,
    question: ["将来のための", "貯金はできている？"],
    answerList: [
      {
        answerNo: 1,
        answer: "貯金できている",
      },
      {
        answerNo: 2,
        answer: "まあまあ貯金できている",
      },
      {
        answerNo: 3,
        answer: "あまり貯金できていない",
      },
      {
        answerNo: 4,
        answer: "貯金できていない",
      },
    ],
  },
  {
    logo: logo11,
    questionNo: 11,
    question: ["家計簿は", "誰がつけている？"],
    answerList: [
      {
        answerNo: 1,
        answer: "自分",
      },
      {
        answerNo: 2,
        answer: "パートナー",
      },
      {
        answerNo: 3,
        answer: "他の人",
      },
      {
        answerNo: 4,
        answer: "家計簿はつけていない",
      },
    ],
  },
  {
    logo: logo12,
    questionNo: 12,
    question: ["買いたいけど予算オーバー！", "どうする？"],
    answerList: [
      {
        answerNo: 1,
        answer: "速攻で買う",
      },
      {
        answerNo: 2,
        answer: "その場でパッと調べてから買う",
      },
      {
        answerNo: 3,
        answer: "じっくり比較検討してから買う",
      },
      {
        answerNo: 4,
        answer: "1ヶ月以上経っても欲しければ買う",
      },
    ],
  },
  {
    logo: logo13,
    questionNo: 13,
    question: ["加入中の保険や投資の内容は", "理解できている？"],
    answerList: [
      {
        answerNo: 1,
        answer: "理解できている",
      },
      {
        answerNo: 2,
        answer: "なんとなく理解できている",
      },
      {
        answerNo: 3,
        answer: "あまり理解できていない",
      },
      {
        answerNo: 4,
        answer: "そもそも金融商品に手を付けていない",
      },
    ],
  },
  {
    logo: logo14,
    questionNo: 14,
    question: ["理想とする", "リスクとリターンのバランスは？"],
    answerList: [
      {
        answerNo: 1,
        answer: "ハイリスク・ハイリターン",
      },
      {
        answerNo: 2,
        answer: "ミドルリスク・ミドルリターン",
      },
      {
        answerNo: 3,
        answer: "ローリスク・ローリターン",
      },
      {
        answerNo: 4,
        answer: "ノーリスク・ノーリターン",
      },
    ],
  },
  {
    logo: logo15,
    questionNo: 15,
    question: ["投資をやってみたいと", "思ったことがある？"],
    answerList: [
      {
        answerNo: 1,
        answer: "すでに始めている",
      },
      {
        answerNo: 2,
        answer: "やりたいと思っている",
      },
      {
        answerNo: 3,
        answer: "興味はあるけどちょっと怖い",
      },
      {
        answerNo: 4,
        answer: "投資には興味がない",
      },
    ],
  },
];
